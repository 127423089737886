import { NgModule, Optional, SkipSelf } from "@angular/core";
import { CommonModule } from "@angular/common";
import { RouterModule } from "@angular/router";
import { LayoutModule } from "./layout/layout.module";
import { SharedModule } from "../shared/shared.module";
import { InstitutionService } from "./services/institution.service";
import { SurveyQuestionService } from "./services/survey-question.service";
import { SurveyAnswerService } from "./services/survey-answer.service";
import { DisclaimerComponent } from "./components/disclaimer/disclaimer.component";
import { EnsureModuleLoadedOnceGuard } from "../../../../shared-lib/src/public-api";
import { CatalogService } from './services/catalog.service';
import { EduFamilySurveyService } from './services/edu-family-survey.service';
import { SurveyUserService } from "./services/survey-user.service";


@NgModule({
  imports: [CommonModule, SharedModule, LayoutModule, RouterModule],
  exports: [RouterModule, LayoutModule, DisclaimerComponent],
  declarations: [DisclaimerComponent],
  entryComponents: [DisclaimerComponent],
  providers: [
    InstitutionService,
    SurveyQuestionService,
    SurveyAnswerService,
    CatalogService,
    EduFamilySurveyService,
    SurveyUserService,
    {
      provide: "Window",
      useFactory: () => window,
    },
  ],
})
export class CoreModule extends EnsureModuleLoadedOnceGuard {
  // Ensure that CoreModule is only loaded into AppModule
  constructor(@Optional() @SkipSelf() parentModule: CoreModule) {
    super(parentModule);
  }
}
