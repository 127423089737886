import { ServiceWorkerModule } from "@angular/service-worker";
import { BrowserModule } from "@angular/platform-browser";
import { NgModule } from "@angular/core";
import { TranslateModule, TranslateLoader } from "@ngx-translate/core";
import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { SharedModule } from "./shared/shared.module";
import { CoreModule } from "./core/core.module";
import {
  SpinnerModule,
  ToasterModule,
} from "../../../shared-lib/src/public-api";

import { environment } from "../environments/environment";
import { AppTranslateLoader } from "./app-translate.loader";
import { DataStoreService } from "./core/services/data-store.service";
import { AppDisableBrowserBtnsModule } from "./app-disable-browser-btns.module";
import { MatSelectFilterModule } from "mat-select-filter";

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppDisableBrowserBtnsModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useClass: AppTranslateLoader,
        deps: [DataStoreService],
      },
    }),
    AppRoutingModule,
    SharedModule,
    CoreModule,
    SpinnerModule,
    ToasterModule,
    MatSelectFilterModule,
    ServiceWorkerModule.register("ngsw-worker.js", {
      enabled: environment.serviceWorker,
    }),
  ],
  providers: [],
  bootstrap: [AppComponent],
})
export class AppModule {}
