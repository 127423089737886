import { Injectable } from "@angular/core";
import { SpinnerComponent } from "./spinner.component";

@Injectable({ providedIn: "root" })
export class SpinnerService {
  private spinnerCache = new Set<SpinnerComponent>();

  register(spinner: SpinnerComponent): void {
    this.spinnerCache.add(spinner);
  }

  unregister(spinner: SpinnerComponent): void {
    this.spinnerCache.delete(spinner);
  }

  show(): void {
    this.spinnerCache.forEach((spinner) => {
      spinner.showSpinner();
    });
  }

  hide(): void {
    this.spinnerCache.forEach((spinner) => {
      spinner.hideSpinner();
    });
  }
}
