<mat-toolbar color="primary">
  <mat-toolbar-row>
    <div class="left-menu">
      <button mat-icon-button (click)="toggleSideNav()">
        <img src="../../../../assets/menu-white-24dp.svg" />
      </button>
    </div>
    <nav class="nav">
      <div class="nav-logo">
        <img class="retina" alt="" [src]="themeService.imageURL$ | async" />
      </div>
    </nav>
    <div class="right-menu">
      <ng-container *ngIf="enableLangDropDown && languages.length > 0">
        <button
          mat-stroked-button
          id="lang-dropdown-btn"
          [matMenuTriggerFor]="aboveMenu"
        >
          {{ languages | findLangByCode: translateService.currentLang }}
        </button>
        <mat-menu #aboveMenu="matMenu" xPosition="before" yPosition="above">
          <button
            mat-menu-item
            *ngFor="
              let lang of languages
                | filterByLangCode: translateService.currentLang
            "
            (click)="switchLang(lang.languageCode)"
          >
            {{ lang.languageCode | uppercase }} ({{ lang.languageDesc }})
          </button>
        </mat-menu>
      </ng-container>
    </div>
  </mat-toolbar-row>
</mat-toolbar>
