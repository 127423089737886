import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { LayoutComponent } from "./core/layout/layout.component";
import { EduGuard } from "./shared/guards/edu-family.guard";
import { DefaultPathGuard } from './shared/guards/default-path.guard';

const routes: Routes = [
  {
    path: ":category/institutions/:institutionId/guidelines",
    canActivate: [EduGuard],
    component: LayoutComponent,
    loadChildren: () =>
      import("./guidelines/guidelines.module").then((m) => m.GuidelinesModule),
  },
  {
    path: ":category/guidelines",
    canActivate: [EduGuard, DefaultPathGuard ],
    component: LayoutComponent,
    loadChildren: () =>
      import("./guidelines/guidelines.module").then((m) => m.GuidelinesModule),
  },
  {
    path: "guidelines/pathways",
    component: LayoutComponent,
    loadChildren: () =>
      import("./pathways/pathways.module").then((m) => m.PathwaysModule),
  },
  {
    path: "guidelines/questiongroup",
    component: LayoutComponent,
    loadChildren: () =>
      import("./question-group/question-group.module").then(
        (m) => m.QuestionGroupModule
      ),
  },
  {
    path: "guidelines/treatment",
    component: LayoutComponent,
    loadChildren: () =>
      import("./treatment/treatment.module").then((m) => m.TreatmentModule),
  },
  {
    path: "survey",
    pathMatch: "full",
    redirectTo: "survey/edu-family",
  },
  {
    path: "survey/:category",
    component: LayoutComponent,
    loadChildren: () =>
      import("./survey/survey.module").then((m) => m.SurveyModule),
  },
  {
    path: "survey/:category/institution/:institutionId",
    
    component: LayoutComponent,
    loadChildren: () =>
      import("./survey/survey.module").then((m) => m.SurveyModule),
  },
  {
    path: "about",
    component: LayoutComponent,
    loadChildren: () =>
      import("./about/about.module").then((m) => m.AboutModule),
  },
  {
    path: "terms",
    component: LayoutComponent,
    loadChildren: () =>
      import("./terms/terms.module").then((m) => m.TermsModule),
  },
  {
    path: "error",
    component: LayoutComponent,
    loadChildren: () =>
      import("./error/error.module").then((m) => m.ErrorModule),
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
