import { LanguageText } from "./language-text.model";

export class GuidelineLangText {
  constructor(
    public name: LanguageText,
    public description: LanguageText,
    public disclaimer: LanguageText,
    public addInformation: LanguageText
  ) {}
}
