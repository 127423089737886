import { Component, Inject } from "@angular/core";
import { MAT_DIALOG_DATA } from "@angular/material/dialog";
import { Disclaimer } from "./disclaimer.model";

@Component({
  selector: "app-mobile-disclaimer",
  templateUrl: "./disclaimer.component.html",
  styleUrls: ["./disclaimer.component.css"],
})
export class DisclaimerComponent {
  constructor(@Inject(MAT_DIALOG_DATA) public data: Disclaimer) {}
}
