import { Pipe, PipeTransform } from "@angular/core";
import { TranslateLanguage } from "../model/translate-language.model";
import { LanguageUtil } from "../utils/language.util";

@Pipe({ name: "filterByLangCode" })
export class FilterByLangCodePipe implements PipeTransform {
  constructor(private languageUtil: LanguageUtil) {}

  transform(
    translateLanguages: TranslateLanguage[],
    langCode: string
  ): TranslateLanguage[] {
    return this.languageUtil.filterTranslationsByLangCode(
      translateLanguages,
      langCode
    );
  }
}
