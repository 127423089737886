import { Injectable } from '@angular/core';

@Injectable({ providedIn: "root" })
export class BrowserSupportUtil {
    private isEdge = /Edge|Edg/.test(navigator.userAgent)
    private isOpera = navigator.userAgent.indexOf(' OPR/') >= 0;
    private isFirefox = navigator.userAgent.toLowerCase().indexOf('firefox') > -1 && ('netscape' in window) && / rv:/.test(navigator.userAgent);
    private isSafari = /Safari/.test(navigator.userAgent) &&  !/Chrome/.test(navigator.userAgent);
    private isChrome = !!(window as any).chrome && !this.isOpera && !this.isSafari;
    private isIE = navigator.userAgent.indexOf("MSIE ") > 0 || !!navigator.userAgent.match(/Trident.*rv\:11\./); 
    private isSamsung = navigator.userAgent.match(/SamsungBrowser/i);
    private isKAIOS = /KAIOS/.test(navigator.userAgent);
    

    public isBrowserCompatible(): boolean {
        var nAgt = navigator.userAgent;
        var majorVersion = parseInt(navigator.appVersion, 10);
        var verOffset;
            
        if (this.isEdge) {
            majorVersion = (/Edge/.test(nAgt)) ? parseInt(nAgt.substring(nAgt.indexOf('Edge/') + 5, nAgt.indexOf('.', nAgt.indexOf('Edge/')))) : parseInt(nAgt.substring(nAgt.indexOf('Edg/') + 4, nAgt.indexOf('.', nAgt.indexOf('Edg/'))));
            return majorVersion >=  80 || majorVersion == 18;         
        }
    
        // In Opera, the true version is after 'Opera' or after 'Version'
        if (this.isOpera) {
            try {
                var raw = navigator.userAgent.match(/OPR\/([0-9]+)\./);
                majorVersion = parseInt(raw[1], 10);
                return majorVersion >=  67;

            } catch (e) {
                return false;
            }
        }
        
        // IE is not supported
        else if (this.isIE) {
            return false;
        }
        else if (this.isKAIOS) {
            return false;
        }
        // Chrome version lower than 44 is not supported
        else if (this.isChrome) {
            var raw = navigator.userAgent.match(/Chrom(e|ium)\/([0-9]+)\./);   
            // if raw == null navigator is skype mobile 
            if(!raw) return true;
            majorVersion = parseInt(raw[2], 10);
            return majorVersion >= 79;
        }

        // In Safari, the true version is after 'Safari' or after 'Version' 
        else if (this.isSafari) {
            // both and safri and chrome has same userAgent
            if (nAgt.indexOf('CriOS') !== -1) {
                return majorVersion <=79;
            } else if (nAgt.indexOf('FxiOS') !== -1) {
                verOffset = nAgt.indexOf('FxiOS');
                return majorVersion >= 73 || majorVersion === 68;
            } else {
                // Safari
                var raw = navigator.userAgent.match(/Version\/([0-9]+)\./);
                majorVersion = parseInt(raw[1], 10);
                return majorVersion >= 11;
            }
        }
        
        // In Firefox, the true version is after 'Firefox' 
        else if (this.isFirefox) {
            return majorVersion >= 73 || majorVersion === 68;
        } else if(this.isSamsung) {
            return majorVersion > 10;
        } 
        
        return true;
    }
}

