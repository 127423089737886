export class MessageType {
  public static INFORMATIONAL = 0;
  public static WARNING = 1;
  public static BUSINESS_ERROR = 2;
  public static SYSTEM_ERROR = 3;
  public static VALIDATION = 4;
  public static INTERNAL = 5;
  public static DEBUG = 6;
  public static HOUSEHOLD = 7;
}
