import { Injectable } from '@angular/core';
import { throwError } from 'rxjs';

@Injectable({ providedIn: "root" })
export class ExternalUrlUtility
{
    processForExternalUrls(htmlText: string,currentUrl: string): string
    {
        let start: number = htmlText.indexOf("<a");
        let end: number = 0;
        if(start < 0)
            return htmlText;
        let output: string = htmlText.substring(0,start);
        while( start >= 0)
        {
            end = htmlText.indexOf("</a>",start);
            if(end <= start)
            {
                throwError("No matching close tag.");
                return;
            }
            end += 4;
            let closeTag: number = htmlText.indexOf(">", start+3);
            if(closeTag <= start)
            {
                throwError("No matching close tag.");
                return;
            }
            let anchorTag: string = htmlText.substring(start+2,closeTag);
            let attributes = this.getAnchorAttributes(anchorTag);
            let hrefAttr = "";
            let targetAttr = "";
            let relAttr = "";
            for(let i: number = 0; i < attributes.length; i++)
            {
                let trimmedData: string = attributes[i].trim();
                if(trimmedData.startsWith("href"))
                    hrefAttr = this.getAttributeValue(trimmedData);
                else if(trimmedData.startsWith("target"))
                    targetAttr = this.getAttributeValue(trimmedData);
                else if(trimmedData.startsWith("rel"))
                    relAttr = this.getAttributeValue(trimmedData);
            } 
            output += htmlText.substring(start,closeTag);
            if(this.isExternalUrl(hrefAttr,currentUrl) && targetAttr === "")
            {  
                output += " target='_blank'";
            }
            output += htmlText.substring(closeTag,end);
            start = htmlText.indexOf("<a",end);
            if(start > 1)
                output += htmlText.substring(end,start);
        }
        output += htmlText.substring(end);
        return output;
    }

    isExternalUrl(hrefAttr: string, currentUrl: string)
    {
        let value = hrefAttr.replace(/['"]+/g, '')
        if( value.startsWith("http"))
        {
            let url: URL = new URL(value);
            if(url.hostname != currentUrl)
                return true;
        }
        return false;
    }
    getAttributeValue(data: string): string
    {
        let valueStart = data.indexOf("=");
        return data.substring(valueStart+1).trim();

    }

    getAnchorAttributes(anchorTag: string): string[]
    {
        return anchorTag.split(" ");
    }
}