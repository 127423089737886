import { Pipe, PipeTransform } from "@angular/core";
import { GuidelineUtil } from "../utils/guideline.util";
import { Decision } from "projects/shared-lib/src/public-api";

@Pipe({ name: "hasCompletedPathways" })
export class HasCompletedPathwaysPipe implements PipeTransform {
  constructor(private guidelineUtil: GuidelineUtil) {}

  transform(decision: Decision): boolean {
    return this.guidelineUtil.hasCompletedPathways(decision);
  }
}
