import { Pipe, PipeTransform } from "@angular/core";
import { TranslateLanguage } from "../model/translate-language.model";
import { LanguageUtil } from "../utils/language.util";
import { GuidelineUtil } from "../utils/guideline.util";
import { Pathway, Decision } from "projects/shared-lib/src/public-api";

@Pipe({ name: "filterSupportedLangPathByCode" })
export class FilterSupportedLangPathByCodePipe implements PipeTransform {
  constructor(
    private languageUtil: LanguageUtil,
    private guidelineUtil: GuidelineUtil
  ) {}

  private findCompletedPathwayById(pathwayId: number) {
    return (pathway: Pathway) =>
      pathway.id === pathwayId &&
      this.guidelineUtil.hasCompletedPathway(pathway);
  }

  private isGuidelineHasCompletedPathway(pathwayId: number) {
    return (guideline: Decision) => {
      const foundPathway = guideline.pathways.find(
        this.findCompletedPathwayById(pathwayId)
      );
      return foundPathway ? true : false;
    };
  }

  private isCompletedPathwayById(guidelines: Decision[], pathwayId: number) {
    const completedPathway = guidelines.filter(
      this.isGuidelineHasCompletedPathway(pathwayId)
    );
    return completedPathway.length > 0 ? true : false;
  }

  private filterSupportedLangById(
    translateLanguages: TranslateLanguage[],
    pathwayId: number
  ): TranslateLanguage[] {
    return translateLanguages.filter((translateLanguage) => {
      const completedPathways = this.isCompletedPathwayById(
        translateLanguage.guidelines,
        pathwayId
      );
      return completedPathways ? true : false;
    });
  }

  private filterSupportedLangPathByCode(
    translateLanguages: TranslateLanguage[],
    pathwayId: number,
    langCode: string
  ) {
    const filteredTranslateLang = this.languageUtil.filterTranslationsByLangCode(
      translateLanguages,
      langCode
    );
    const supportedLanguages = this.filterSupportedLangById(
      filteredTranslateLang,
      pathwayId
    );

    return this.languageUtil
      .mapLanguagesToLangCode(supportedLanguages)
      .reverse();
  }

  transform(
    translateLanguages: TranslateLanguage[],
    pathwayId: number,
    langCode: string
  ): string[] {
    return this.filterSupportedLangPathByCode(
      translateLanguages,
      pathwayId,
      langCode
    );
  }
}
