import { Treatment } from "./decision.model";
import { LanguageText } from "./language-text.model";

export class TreatmentLang {
  constructor(
    public language: string,
    public readable: Treatment,
    public editable: LanguageText
  ) {}
}
