import { Injectable } from "@angular/core";
import { Pathway, Decision } from "projects/shared-lib/src/public-api";

@Injectable({ providedIn: "root" })
export class GuidelineUtil {
  constructor() {}

  private isSizeGreaterThanOne(pathway: Pathway): boolean {
    const qGroupsSize = pathway.questionGroupList.length;
    const treatmentsSize = pathway.treatmentList.length;
    return qGroupsSize >= 1 && treatmentsSize >= 1;
  }

  private hasChildren(pathways: Pathway[]): boolean {
    return pathways.some(this.isSizeGreaterThanOne);
  }

  public hasCompletedPathway(pathway: Pathway) {
    if (pathway) {
      return this.isSizeGreaterThanOne(pathway);
    } else {
      return false;
    }
  }

  public hasCompletedPathways(decision: Decision): boolean {
    if (decision.pathways.length >= 1) {
      return this.hasChildren(decision.pathways);
    } else {
      return false;
    }
  }
}
