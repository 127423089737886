
<h5 mat-dialog-title>{{ "Select notifications language" | translate }} </h5> 
<form [formGroup]="form">
    <mat-radio-group formControlName="langControl"
    class="vertical-radio-group"
    
    >
    <mat-radio-button class="radio-option" *ngFor="let lang of languages" [value]="lang.id">
      {{lang.languageDesc}}
    </mat-radio-button>
  </mat-radio-group>
  <div class="col-sm-12" *ngIf="form.touched && form.invalid">
    <p class="errorMsg">
      The field is required
    </p>
  </div>
  <div class="buttons">
      <button mat-flat-button (click)="onCancel()" color="primary">
          {{ "Cancel" | translate }}
      </button>
      <button mat-flat-button class="save-btn" type="submit" (click)="onSubmit()" color="primary">
          {{ "Save" | translate }}
      </button>
  </div>
</form> 