import { Pipe, PipeTransform } from "@angular/core";
import { FormGroup } from "@angular/forms";
import { Institution } from "projects/shared-lib/src/public-api";

@Pipe({ name: "filterValidInstMemberOptions" })
export class filterValidInstMemberOptionsPipe implements PipeTransform {
  constructor() {}

  private filterValidInstMemberOptions(institutions: Institution[]): Institution[] {
    return institutions.filter((i) => i.hasPublishedPathwayTrees);
  }

  transform(institutions: Institution[]): Institution[] {
    return this.filterValidInstMemberOptions(institutions);
  }
}
