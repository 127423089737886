import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { catchError, tap } from "rxjs/operators";


import {
  InquiryRequest,
  InquiryResponse,
  Category,
} from "projects/shared-lib/src/public-api";
import { ServiceUtil } from "../../shared/utils/service-util";
@Injectable({ providedIn: "root" })
export class CategoryService {
  private categoryUrl = `${this.serviceUtil.rootUrl}/category`;

  constructor(private serviceUtil: ServiceUtil, private http: HttpClient) {
    this.serviceUtil = serviceUtil;
    this.http = http;
    this.handleGetCategoriesResponse = this.handleGetCategoriesResponse.bind(
      this
    );
  }

  private handleGetCategoriesResponse(response: InquiryResponse<Category>) {}

  getCategories(data: InquiryRequest) {
    const url = `${this.categoryUrl}/fetch`;
    return this.http
      .post<InquiryResponse<Category>>(
        url,
        data,
        this.serviceUtil.getCommonHeaders()
      )
      .pipe(
        catchError(this.serviceUtil.handleError),
        tap(this.handleGetCategoriesResponse)
      );
  }
  
}
