import { PersistenceAction } from "../constant/persistence-action.constant";

export class ChildrenModelBase {
  id: number;
  rowStatus?: boolean;
  displayOrder?: number;
  createdBy?: string;
  createdDate?: Date;
  updatedBy?: string;
  updatedDate?: Date;
  modelAction: PersistenceAction;
}
