import { Injectable } from "@angular/core";
import { openDB, IDBPDatabase } from "idb";
import { AppStore } from "../../shared/interface/app-store.interface";
import { Usage, Language } from "projects/shared-lib/src/public-api";
import { UsageControlUtil } from "../../shared/utils/usage.util";
import { BehaviorSubject } from 'rxjs';

@Injectable({ providedIn: "root" })
export class LocalDatabaseService {
  public dbReady$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  private db: IDBPDatabase<AppStore>;
  constructor(private usageControlUtil: UsageControlUtil) {
    this.connectToDb = this.connectToDb.bind(this);
    this.addUsage = this.addUsage.bind(this);
    this.getAllUsage = this.getAllUsage.bind(this);
    this.deleteAllUsage = this.deleteAllUsage.bind(this);
    this.rewritteUsage = this.rewritteUsage.bind(this);
    this.getAllIncompleteUsage = this.getAllIncompleteUsage.bind(this);
    this.deleteAllIncompleteUsage = this.deleteAllIncompleteUsage.bind(this);
    this.addLanguages = this.addLanguages.bind(this);
    this.getAllLanguage = this.getAllLanguage.bind(this);
    this.deleteAllLanguage = this.deleteAllLanguage.bind(this);
    this.connectToDb();
  }

  private async connectToDb() {
    openDB<AppStore>("mobile-app", 1, {
      upgrade(db) {
        db.createObjectStore("usage-store", {
          autoIncrement: true,
        });
        db.createObjectStore("incomplete-usage-store", {
          autoIncrement: true,
        });
        db.createObjectStore("languages-store", {
          autoIncrement: true,
        });
      },
    }).then(db => {
      this.db = db;
      this.dbReady$.next(true);
    });
  }

  public async addUsage(usage: Usage): Promise<number> {
    const usageStr = this.usageControlUtil.toString(usage);
    return await this.db.put("usage-store", usageStr);
  }

  public async getAllUsage(): Promise<Usage[]> {
    const usageStrings = await this.db.getAll("usage-store");
    return this.usageControlUtil.toUsage(usageStrings);
  }

  public async deleteAllUsage(): Promise<void> {
    return this.db.clear("usage-store");
  }

  public async rewritteUsage(usage: Usage): Promise<number> {
    this.deleteAllIncompleteUsage().then(() => {}).catch(() => {});
    const usageStr = this.usageControlUtil.toString(usage);
    return await this.db.put("incomplete-usage-store", usageStr);
  }

  public async getAllIncompleteUsage(): Promise<Usage[]> {
    const usageStrings = await this.db.getAll("incomplete-usage-store");
    return this.usageControlUtil.toUsage(usageStrings);
  }

  public async deleteAllIncompleteUsage(): Promise<void> {
    return this.db.clear("incomplete-usage-store");
  }

  public async addLanguages(languages: Language[]): Promise<number> {
    const languageStr = JSON.stringify(languages);
    return await this.db.put("languages-store", languageStr);
  }

  public async getAllLanguage(): Promise<Language[]> {
    const usageStrings = await this.db.getAll("languages-store");
    if (usageStrings.length > 0) {
      return JSON.parse(usageStrings[0]);
    } else {
      return [];
    }
  }

  public async deleteAllLanguage(): Promise<void> {
    return await this.db.clear("languages-store");
  }
}
