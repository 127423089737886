import { Injectable } from "@angular/core";
import {
  CanActivate,
  Router,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
} from "@angular/router";
import { LocalStorageUtil } from "../utils/local-storage.util";
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: "root",
})
export class EduGuard implements CanActivate {
  constructor(
    private router: Router,
    private localStorageUtil: LocalStorageUtil,
    private translateService: TranslateService
  ) {}

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    var category = "";
    if (next.params.category === undefined || next.params.category === null)
    {
      var categoryName = window.sessionStorage["category"];
      if(categoryName != null)
        category = categoryName;
      else
        return true;
    }
    else
      category = next.params.category;
    const usage = this.localStorageUtil.getUsageInfo(category);
    if (next?.queryParams?.source) {
      usage.referralSource = next.queryParams.source;
      this.localStorageUtil.saveUsageInfo(category,usage);
    }
    if(next.queryParams && next.queryParams.loggedIn){
      return true;
    }
    let cname: string = category.toLowerCase();
    this.localStorageUtil.saveGuidelinesParams(category,next.params);
    if (cname === "edu-family") {
      if (!this.localStorageUtil.getHouseHoldId(category)) {
        this.navigateToSurbeyPage(cname, next);
        return false;
      }
    }
    else if (next.params) {
      if (!this.localStorageUtil.getSurveyId(category)) {
        this.navigateToSurbeyPage(cname,next);
        return false;
      }
    }
    else
    {
      console.log("Survey screen logic is not defined!!");
    }
    return true;
  }

  private navigateToSurbeyPage(categoryName: string, next: ActivatedRouteSnapshot){
    console.log("navigateToSurbeyPage");
    console.log(categoryName, next.params.institutionId);
    let url = `/survey/${categoryName}`;
      url = (next.params.institutionId) ? `/survey/${categoryName}/institution/${next.params.institutionId}` : url;
      var queryParams: { lang; loggedIn?; deviceToken?} = { lang: this.translateService.currentLang };
      if(next.queryParams && next.queryParams.loggedIn){
        queryParams.loggedIn = next.queryParams.loggedIn;
      }
      if(next.queryParams && next.queryParams.deviceToken){
        queryParams.deviceToken = next.queryParams.deviceToken;
      }
      console.log("navigateToSurbeyPage2222");
      console.log(next);
      this.router.navigate([url],  { queryParams } );
  }
}

