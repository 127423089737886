// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.
// isAutomation is used for automated testing environments.
export const environment = {
  production: false,
  
  //baseUrl: 'http://ecdsadminapp.rito.unmc.edu:53568',
  //baseUrl: 'http://ecdsapi.rito.unmc.edu',
  //userAppUrl: 'http://ecdsuserapp.rito.unmc.edu',
 
  baseUrl: 'https://ecdsapi.rito.unmc.edu',
  userAppUrl: 'https://ecdsuserapp.rito.unmc.edu',
 
  //baseUrl: 'http://10.8.22.183:53568',
  //userAppUrl: "http://10.8.22.183:4200",  
  //baseUrl: 'http://localhost:53568',
  //userAppUrl: "http://localhost:4200/",
  isAutomation: false

};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
