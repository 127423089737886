import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { catchError, tap } from "rxjs/operators";

import { ServiceUtil } from "../../shared/utils/service-util";
import { SurveyAnswerRequest } from "../../shared/model/survey-answer-request.model";
import {
  InquiryResponse,
  SurveyAnswer,
  Visitor,
} from "../../../../../shared-lib/src/public-api";

@Injectable()
export class SurveyAnswerService {
  private surveyAnswerUrl = `${this.serviceUtil.rootUrl}/surveyanswer`;

  constructor(private serviceUtil: ServiceUtil, private http: HttpClient) {
    this.http = http;
    this.handleGetAnswersResponse = this.handleGetAnswersResponse.bind(this);
    this.handleSaveAnswersResponse = this.handleSaveAnswersResponse.bind(this);
  }

  private handleGetAnswersResponse(response: InquiryResponse<SurveyAnswer>) {}

  getAnswers(data: Visitor) {
    const url = `${this.surveyAnswerUrl}/fetchbyusername`;
    return this.http
      .post<InquiryResponse<SurveyAnswer>>(
        url,
        data,
        this.serviceUtil.getCommonHeaders()
      )
      .pipe(
        catchError(this.serviceUtil.handleError),
        tap(this.handleGetAnswersResponse)
      );
  }

  private handleSaveAnswersResponse(response: InquiryResponse<any>) {}

  saveAnswers(data: SurveyAnswerRequest) {
    const url = `${this.surveyAnswerUrl}/save`;
    return this.http
      .post<InquiryResponse<SurveyAnswerRequest>>(
        url,
        data,
        this.serviceUtil.getCommonHeaders()
      )
      .pipe(
        catchError(this.serviceUtil.handleError),
        tap(this.handleSaveAnswersResponse)
      );
  }
}
