import { TableType } from "../constant/table-type.constant";

export class LanguageText {
  public id: number;
  public tableId: number;
  public tableTypeId: TableType;
  public languageId: number;
  public key: string;
  public value: string;
  public rowStatus: boolean;
  public createdBy: string;
  public createdDate: Date;
  public updatedBy?: string;
  public updatedDate?: Date;
  public modelAction: number;
}
