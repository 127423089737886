import { Facility } from './facility.model';

export class Institution {
  public id: number;
  public description: string;
  public logo: string;
  public isDefault: boolean;
  public themeElements: InstitutionThemeElement[] = [];
  public institutionTypeId: number;
  public contactName: string;
  public contactPhone: string;
  public email: string;
  public emailSubject: string;
  public facilities: Facility[] = [];
  public showChildrenInstitutions: boolean;
  public hasPublishedPathwayTrees: boolean;
  public sendNotifications: boolean;
  public createdBy: string;
  public createdDate: Date;
  public updatedBy: string;
  public updatedDate: Date;
  public modelAction: number;
  public rowStatus: boolean;
}

export class InstitutionThemeElement {
  public id: number;
  public institutionId: number;
  public themeElementId: number;
  public name: string;
  public value: string;
  public rowStatus: boolean;
  public createdBy: string;
  public createdDate: Date;
  public updatedBy: string;
  public updatedDate: Date;
  public modelAction: number;
}

export class ThemeElement {
  public id: number;
  public name: string;
  public type: string;
  public hint: string;
  public rowStatus: boolean;
  public createdBy: string;
  public createdDate: Date;
  public updatedBy: string;
  public updatedDate: Date;
  public modelAction: number;
}
