import { Component, OnInit } from '@angular/core';
import { CategoryDataTypeUtil } from 'projects/AdminApp/src/app/shared/util/category-data-type.util';
import { LocalStorageUtil } from '../../utils/local-storage.util';

@Component({
  selector: 'app-mobile-household-header',
  templateUrl: './household-header.component.html',
  styleUrls: ['./household-header.component.css']
})
export class HouseholdHeaderComponent implements OnInit {
  householdId: string = null;
  isHouseHoldVisible = false;
  userTypeName: string = 'edu-family';
  constructor(public localStorageUtil: LocalStorageUtil, public dataTypeUtil: CategoryDataTypeUtil) { }

  private showHouseholdHeader(id: string, categoryDesc: string) {
   return id && this.dataTypeUtil.isEduFamily(categoryDesc) ? true : false;
  }

  ngOnInit(): void {
    console.log("household header")
    if(window.sessionStorage.getItem("category")) this.userTypeName = window.sessionStorage.getItem("category");
    this.householdId = this.localStorageUtil.getHouseHoldId(this.userTypeName);
    const  params = this.localStorageUtil.getGuidelinesParams(this.userTypeName);
    this.isHouseHoldVisible = this.showHouseholdHeader(this.householdId, params?.category);
  }

}
