import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({ providedIn: "root" })
export class QuestionListenerUtil {
    userActivity;
    public userActivity$: Subject<any> = new Subject();
    public userInactiveOrClosed$: Subject<any> = new Subject();

    // timeout on miliseconds
    readonly timeout = 60000 * 5;
    private infoSaved = false;


    constructor() {
        this.userActivity$.subscribe(() => {
            clearTimeout(this.userActivity);
            this.setTimeout();
        });
    }

    resetStatus(saved: boolean){
        this.infoSaved = saved;
    }

    isInfoSaved(): boolean {
        return this.infoSaved;
    }

    setTimeout() {
        this.userActivity = setTimeout(() => {
                this.userInactiveOrClosed$.next(undefined)
                this.infoSaved = true;
        }, this.timeout);
    }

}