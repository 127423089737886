
import { BaseClass } from "./decision.model";
import { LanguageText } from "./language-text.model";
import { QuestionOption } from "./question-option.model";
import { TableFilterGroup } from "./table-filter-group.model";

export class SurveyQuestion extends BaseClass {
  public description: string;
  public questionCtrlTypeId: number;
  public requireAnswer: boolean;
  public encryptedAnswer: boolean;
  public isPathwayLauncher: boolean;
  public categoryId: number;
  public questionOptions: QuestionOption[];
  public tableFilterGroups: TableFilterGroup[];
  public textList: LanguageText[];
}
