import { Injectable } from "@angular/core";
import { ScoreToNextObject, Decision, Pathway, QuestionGroup, Option } from "projects/shared-lib/src/public-api";
import { LocalStorageUtil } from "./local-storage.util";

/**
 * Helper class to help in processing survey variables.
 */
@Injectable({ providedIn: "root" })
export class SurveyValiableUtil 
{
    constructor( private localStorageUtil: LocalStorageUtil ) {}
    
    /**
     * Finds survey variables and replaces with values.
     * @param input Input string to look for variables
     * @returns String with variable substitutions.
     */
    substituteString(input: string,variables: Map<string,string>): string{
        let output: string = '';
        let start = input.indexOf("$$");
        let end = 0;
        while(start != -1)
        {
            output += input.substring(end,start);
            end = input.indexOf("$$",start+2);
            if(end != -1)
            {
                let key = input.substring(start+2,end);
                end += 2;
                if(!variables.has(key))
                {
                    output += input.substring(start,end);
                }
                else{
                    output += variables.get(key);
                }
            }
            else
            {
                output += input.substring(start);
                break;
            }
            start = input.indexOf("$$",end);
        }
        output += input.substring(end);
        return output;
    }

/**
   * Call this method to substitute string in variables in treatments and questions. 
   * @param guidelines Guidelines array
   */
  applySurveyVariables(userTypeName: string,guidelines: Decision[])
  {
    let variables = this.localStorageUtil.getVariables(userTypeName);
    for(let g = 0; g < guidelines.length; g++)
    {
      var guideline = guidelines[g];
      for(let p = 0; p < guideline.pathways.length; p++)
      {
        var pathway = guideline.pathways[p] as Pathway;
        for( let t = 0; t < pathway.treatmentList.length; t++)
        {
          var treatment = pathway.treatmentList[t];
          treatment.text = this.substituteString(treatment.text,variables);
        }
        this.processQuestionGroupTree(pathway.questionGroupList,pathway.startingQuestionGroupId,variables);
        for( let g = 0; g < pathway.questionGroupList.length; g++)
        {
          var group = pathway.questionGroupList[g];
          for (let q = 0; q < group.questions.length; q++)
          {
            var question = group.questions[q];
            question.text = this.substituteString(question.text,variables);
          }
        }
      }
    }
  }

  /**
   * Method process the tree and when variables match removes nodes.
   * @param questionGroups 
   * @param pathwayRootGroupId 
   * @param variables 
   */
  processQuestionGroupTree(questionGroups: QuestionGroup[], pathwayRootGroupId: number, variables: Map<string,string>)
  {
    let groupMap: Map<number,QuestionGroup> = new Map<number,QuestionGroup>();
    for( let g = 0; g < questionGroups.length; g++)
    {
        groupMap.set(questionGroups[g].id,questionGroups[g]);
    }
    var rootExists = groupMap.has(pathwayRootGroupId);
    if (rootExists) 
    {
        var root = groupMap.get(pathwayRootGroupId);
        this.processQuestionGroup(groupMap,variables,root);
    }
    else 
    {
        console.log("Root node note found");
    }
  }
/**
 * Recursive method that actually does depth first search to prcess data.
 * @param groupMap 
 * @param variables 
 * @param parent 
 */
    public processQuestionGroup( groupMap: Map<number, QuestionGroup>, variables: Map<string, string>, 
        parent: QuestionGroup ) 
    {
        let nextObjs = this.getNextObjects(parent);
        nextObjs.forEach( n => {
            if(n.nextQuestionGroupId !== undefined || n.nextQuestionGroupId !== 0)
            {
                let childGroup = groupMap.get(n.nextQuestionGroupId);
                if(childGroup !== undefined)
                {
                    this.processQuestionGroup(groupMap,variables,childGroup);
                    this.RemoveChildGroupIfVariablesMatch(childGroup, variables, n);
                }
            }
        });
    }

    /**
     * Assigns childgroup option next objects to parents option next group element 
     * @param childGroup 
     * @param variables 
     * @param n 
     */
    public RemoveChildGroupIfVariablesMatch(childGroup: QuestionGroup, variables: Map<string, string>, n: Option | ScoreToNextObject) 
    {
        if (!childGroup.isScoreBased) 
        {
            var childNextObjs = this.getNextObjects(childGroup);
            childNextObjs.forEach(cn => {
            let op = cn as Option;
            if (op !== null) 
            {
                if (op.variableList !== undefined && op.variableList.length !== 0)
                {
                    for(let i = 0; i < op.variableList.length; i++)
                    {
                        if( variables.has(op.variableList[i].text)) 
                        {
                            n.nextQuestionGroupId = op.nextQuestionGroupId;
                            n.treatmentId = op.treatmentId;
                        }
                    }
                    
                }
            }
        });
    }
    }

    /**
     * Returns either Scores or Options which contain next objects for a group.
     * @param group 
     * @returns 
     */
    public getNextObjects(group: QuestionGroup): (Option | ScoreToNextObject)[]
    {
        let retObjs = [];
        if (!group.isScoreBased) //should be only one question
        {
            group.questions.forEach(q => {
                q.options.forEach(o => {
                    retObjs.push(o);
                });
            });
        }
        else{
            group.scoreToNextObjectList.forEach( s=>{
                retObjs.push(s);
            });
        }
        return retObjs;
    }
}