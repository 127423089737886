<div *ngIf="numberOfWaits > 0" class="spinner-overlay" id="spinner">
  <div class="spinner">
    <mat-spinner
      [diameter]="40"
      [mode]="'indeterminate'"
      style="margin: 0 auto;"
    >
    </mat-spinner>
  </div>
</div>
