import { Injectable } from "@angular/core";
import { ValidationErrors } from "@angular/forms";
import { QuestionGroup, Question } from "../model/decision.model";
import { HiLowRange } from "../model/hilow.model";

@Injectable({ providedIn: "root" })
export class ScoreListValidator {
  maxHighValue = 1500;
  getWeightedRangeForMultiSelect(question: Question): number {
    let total = 0;
    for (let j = 0; j < question.options.length; j++) {
      let w = +question.options[j].weight;
      total = total + w;
    }
    return +total;
  }

  getWeightedRangeForSingleSelect(question: Question): HiLowRange {
    let range = new HiLowRange();
    range.low = +question.options[0].weight;
    range.high = +question.options[0].weight;
    for (let j = 1; j < question.options.length; j++) {
      if (range.low > question.options[j].weight)
        range.low = +question.options[j].weight;
      else if (range.high < question.options[j].weight)
        range.high = +question.options[j].weight;
    }
    return range;
  }

  getQuestionWeightedRange(group: QuestionGroup): Set<number> {
    let range = new HiLowRange();
    range.low = 0;
    range.high = 0;
    for (let i = 0; i < group.questions.length; i++) {
      let question = group.questions[i];
      let result = this.getWeightedRangeForSingleSelect(question);
      if (question.multipleSelection) {
        result.high = this.getWeightedRangeForMultiSelect(question);
      }
      range.low += result.low;
      range.high += result.high;
    }
    let output = new Set<number>();
    for (let i = range.low; i <= range.high; i++) output.add(i);
    return output;
  }
  validate(group: QuestionGroup): ValidationErrors {
    if (!group.isScoreBased) return null;
    let totalRange = this.getQuestionWeightedRange(group);
    let scoreRange = new Set<number>();
    for (let i = 0; i < group.scoreToNextObjectList.length; i++) {
      let scoreObject = group.scoreToNextObjectList[i];
      let data = scoreObject.score.replace(/\s/g, "");
      let result = this.parseScore(data);
      if (result == null) {
        return ["key", "Invalid range"];
      }
      for (let j = result.low; j <= result.high; j++) {
        if (!scoreRange.has(j)) {
          scoreRange.add(j);
        } else {
          return ["key", "Overlapping range: " + scoreObject.score];
        }
        if (totalRange.has(j)) totalRange.delete(j);
      }
    }
    if (totalRange.size > 0) {
      let error: string = "";
      for (let s of totalRange) {
        error += s.toString();
        error += ",";
      }
      return [
        "key",
        "Whole range is not covered. Here are items missing: " + error,
      ];
    }
    return null;
  }

  parseScore(score: string): HiLowRange {
    const reg1: RegExp = /^(<|>)(=)?(\s)*[0-9]*(\s)*$/; // < number or > number or <= number or >= number
    const reg2: RegExp = /^[0-9]+(\s)*[-]{1}(\s)*[0-9]+$/; //Eg: 10 - 20 or 10-20
    const reg3: RegExp = /^[0-9]+$/;
    if (reg1.test(score)) {
      if (score[0] == "<") return this.lessThanOrEqual(score);
      else return this.greaterThanOrEqual(score);
    } else if (reg2.test(score)) {
      return this.rangeBased(score);
    } else if (reg3.test(score)) {
      let obj = new HiLowRange();
      obj.low = +score;
      obj.high = obj.low;
      return obj;
    }
    return null;
  }

  greaterThanOrEqual(score: string): HiLowRange {
    let result = new HiLowRange();
    let i = 1;
    if (score[i] == "=") i = 2;
    score = score.substring(i);
    let numericData = +score;
    result.low = i == 1 ? numericData + 1 : numericData; //i==2 when >=
    result.high = this.maxHighValue;
    return result;
  }

  lessThanOrEqual(score: string): HiLowRange {
    let result = new HiLowRange();
    let i = 1;
    if (score[i] == "=") i = 2;
    score = score.substring(i);
    let numericData = +score;
    result.high = i == 1 ? numericData - 1 : numericData; //i==2 when >=
    result.low = 0;
    return result;
  }

  rangeBased(score: string): HiLowRange {
    let data = score;
    data = data.replace(/\s/g, "");
    let array = data.split("-");
    if (array.length == 2) {
      let result = new HiLowRange();
      result.low = +array[0];
      result.high = +array[1];
      return result;
    }
    return null;
  }
}
