import { Injectable } from "@angular/core";

import { LocalStorageItem } from "../constant/local-storage-item.constant";
import { Account } from "../model/account.model";
import { QuestionGroup } from "../model/decision.model";

@Injectable({ providedIn: "root" })
export class LocalStorageUtil {
  constructor() {}

  getAdmin(): Account {
    const adminData: {
      username: string;
      _token: string;
      _expiresIn: number;
    } = JSON.parse(localStorage.getItem(LocalStorageItem.ADMIN_DATA));
    if (adminData) {
      return new Account(
        adminData.username,
        adminData._token,
        adminData._expiresIn
      );
    } else {
      return null;
    }
  }

  saveAdmin(data) {
    localStorage.setItem(LocalStorageItem.ADMIN_DATA, JSON.stringify(data));
  }

  deleteAdmin() {
    localStorage.removeItem(LocalStorageItem.ADMIN_DATA);
  }

  // remove duplicate method from MobileApp/local-storage
  saveHouseHoldId(id: string) {
    localStorage.setItem(LocalStorageItem.HOUSEHOLD_ID, id);
  }

  getHouseHoldId(): string {
    return localStorage.getItem(LocalStorageItem.HOUSEHOLD_ID);
  }

  saveQsGroup(qsGroup: QuestionGroup): void {
    localStorage.setItem(LocalStorageItem.QS_GROUP, JSON.stringify(qsGroup));
  }

  getQsGroup(): QuestionGroup {
    const qsGroup  = JSON.parse(localStorage.getItem(LocalStorageItem.QS_GROUP));
    return qsGroup;
  }

  deleteQsGroup(): void {
    localStorage.removeItem(LocalStorageItem.QS_GROUP);
  }
}
