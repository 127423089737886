import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { catchError, tap } from "rxjs/operators";

import { ServiceUtil } from "../../shared/utils/service-util";

import {
  InquiryRequest,
  InquiryResponse,
  
} from "../../../../../shared-lib/src/public-api";
import { of, BehaviorSubject } from 'rxjs';
import { SurveyUser } from "projects/shared-lib/src/lib/model/survey-user.model";

@Injectable()
export class SurveyUserService {
  private surveyUserUrl = `${this.serviceUtil.rootUrl}/surveyUser`;

  constructor(private serviceUtil: ServiceUtil, private http: HttpClient) {
  }

  private handleResponse(
    response: InquiryResponse<SurveyUser>
  ) {}

  getSurveyById(id) {
    const url = `${this.surveyUserUrl}/fetchById?id=${id}`;
    return this.http
      .post<InquiryResponse<SurveyUser>>(
        url,
        null,
        this.serviceUtil.getCommonHeaders()
      )
      .pipe(
        catchError(this.serviceUtil.handleError),
        tap(this.handleResponse)
      );
  }

  getSurveyByEmail(email: string, userTypeId: number) {
    const url = `${this.surveyUserUrl}/fetchByEmail`;
    const data = {
      email: email,
      userTypeId: userTypeId
    }
    return this.http
      .post<InquiryResponse<SurveyUser>>(
        url,
        data,
        this.serviceUtil.getCommonHeaders()
      )
      .pipe(
        catchError(this.serviceUtil.handleError),
        tap(this.handleResponse)
      );
  }

  getSurveyByEmailUserTypeName(email: string, userTypeName: string) {
    const url = `${this.surveyUserUrl}/fetchByEmail`;
    const data = {
      email: email,
      userTypeName: userTypeName
    }
    return this.http
      .post<InquiryResponse<SurveyUser>>(
        url,
        data,
        this.serviceUtil.getCommonHeaders()
      )
      .pipe(
        catchError(this.serviceUtil.handleError),
        tap(this.handleResponse)
      );
  }

  saveSurvey(survey: SurveyUser) {
    const url = `${this.surveyUserUrl}/save`;
    return this.http
      .post<InquiryResponse<any>>(
        url,
        survey,
        this.serviceUtil.getCommonHeaders()
      )
      .pipe(
        catchError(this.serviceUtil.handleError),
        tap(this.handleResponse)
      );
  }

}