import { ChildrenModelBase } from "./children-base.model";

export class NotificationSubscription extends ChildrenModelBase {
  public endPoint: string;
  public institutionId: number;
  public categoryName: string;
  public p256DH: string;
  public auth: string;
  public languageId: number;
  public surverUserId: number;
}
