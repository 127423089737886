import { Pipe, PipeTransform } from "@angular/core";
import { Institution } from 'projects/shared-lib/src/public-api';

@Pipe({ name: "filterInstitutionsByType" })
export class FilterInstitutionsByTypePipe implements PipeTransform {
  constructor() {}

  private filterInstitutionByTypeId(institutions: Institution[], typeId: number): Institution[] {
   return institutions.filter(institution => institution.institutionTypeId === typeId);
  }

  transform(
    institutions: Institution[],
    typeId: number,
  ): Institution[] {
    if (typeId) {
      return this.filterInstitutionByTypeId(institutions, typeId);
    } else {
      return [];
    }
  }
}
