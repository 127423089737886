import { Component, OnInit } from "@angular/core";
import { UrlUtil } from "../../../shared/utils/url.util";
import { ThemeService } from "../../services/theme.service";
import { TranslateService } from "@ngx-translate/core";
import { LocalStorageUtil } from "../../../shared/utils/local-storage.util";
import { ReferralSource } from "../../../shared/constant/referral-source.constant";
import { ActivatedRoute } from "@angular/router";

@Component({
  selector: "app-sidebar",
  templateUrl: "./sidebar.component.html",
  styleUrls: ["./sidebar.component.css"],
})
export class SidebarComponent implements OnInit {
  isAndroid = false;
  guidelinesRoute: string;
  surveyLink: string;
  privacyLink: string;
  userTypeName: string;

  constructor(
    public urlUtil: UrlUtil,
    private localStorageUtil: LocalStorageUtil,
    public themeService: ThemeService,
    public translateService: TranslateService,
    private route: ActivatedRoute
  ) {}

  ngOnInit() {
    this.userTypeName = (this.route.snapshot.paramMap.get('category')) ? this.route.snapshot.paramMap.get('category') : window.sessionStorage.getItem("category");
    this.updateLinks();
  }

  public guidelinesUrl(): string {
    this.updateLinks();
    return this.urlUtil.getGuidelinesUrl(this.userTypeName);
  }

  private isAdroidPlatform(): boolean {
    var usage = this.localStorageUtil.getUsageInfo(this.userTypeName);
    if(usage === undefined)
      return false;
    const { referralSource } = this.localStorageUtil.getUsageInfo(this.userTypeName);
    return referralSource?.toLocaleLowerCase() === ReferralSource.ANDROID
      ? true
      : false;
  }
  private updateLinks() {
    const params = this.localStorageUtil.getGuidelinesParams(this.userTypeName);
    var lcUserType = this.userTypeName.toLowerCase();
    if (this.userTypeName === "edu-family") {
      this.privacyLink = 'https://documentsumnc.blob.core.windows.net/documents/SymptomChecker%20FAQ%20and%20Privacy%20Policy.pdf';
      // this.privacyLink = 'https://www.childrensomaha.org/wp-content/uploads/2020/08/SymptomChecker.pdf'
    } 
    else if(lcUserType === "vipbasic")
    {
      this.privacyLink = 'https://www.privacypolicies.com/live/55f85fba-22a0-4e54-a52e-f908a9c9f502'
    }
    else if( lcUserType === "moveupblue" || lcUserType === "moveupgreen" )
    {
      this.privacyLink = 'https://www.unmc.edu/chri/_documents/moveup_privacy_policy.pdf'
    }
    this.surveyLink = `/survey/${this.userTypeName}`;
    const preferredInstitution = this.localStorageUtil.getPreferedInstitution(this.userTypeName);
    if( preferredInstitution != undefined && preferredInstitution !== null)
      this.surveyLink = `/survey/${this.userTypeName}/institution/${preferredInstitution.id}`
    else if(params?.institutionId !== undefined)
      this.surveyLink = (params?.institutionId !== undefined) ? `/survey/${this.userTypeName}/institution/${params.institutionId}` : this.surveyLink;
    this.isAndroid = this.isAdroidPlatform();
  }
}

