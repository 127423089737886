import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { catchError, tap } from "rxjs/operators";

import { ServiceUtil } from "../../shared/utils/service-util";

import {
  InquiryRequest,
  InquiryResponse,
  SurveyQuestion,
} from "../../../../../shared-lib/src/public-api";

@Injectable()
export class SurveyQuestionService {
  private surveyQuestionUrl = `${this.serviceUtility.rootUrl}/surveyquestion`;

  constructor(private serviceUtility: ServiceUtil, private http: HttpClient) {
    this.serviceUtility = serviceUtility;
    this.http = http;
    this.handleGetQuestionsResponse = this.handleGetQuestionsResponse.bind(
      this
    );
  }

  private handleGetQuestionsResponse(
    response: InquiryResponse<SurveyQuestion>
  ) {}

  getQuestions(data: InquiryRequest) {
    const url = `${this.surveyQuestionUrl}/fetch`;
    return this.http
      .post<InquiryResponse<SurveyQuestion>>(
        url,
        data,
        this.serviceUtility.getCommonHeaders()
      )
      .pipe(
        catchError(this.serviceUtility.handleError),
        tap(this.handleGetQuestionsResponse)
      );
  }
}
