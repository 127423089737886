import { TranslateLoader } from "@ngx-translate/core";
import { Observable } from "rxjs";
import { DataStoreService } from "./core/services/data-store.service";

export class AppTranslateLoader implements TranslateLoader {
  constructor(private dataStoreService: DataStoreService) {}

  getTranslation(langCode: string): Observable<{}> {
    return this.dataStoreService.getTranslations(langCode);
  }
}
