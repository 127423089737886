import { ChildrenModelBase } from './children-base.model';

export class EduFamilySurvey extends ChildrenModelBase {
    houseHoldId: string;
    institutionId: number;
    facilityId: number;
    unitId: number;
    genderId: number;
    raceId: number;
    ethnicityId: number;
    preferredInstitutionId: number;
    afterHourInstitutionId: number;
    firstName: string;
    lastName: string;
    email: string;
}
