import { Component, OnInit, AfterViewInit, OnDestroy, HostListener } from "@angular/core";
import { LocalStorageUtil } from "./shared/utils/local-storage.util";
import { UsageData } from "./shared/model/usage-data.model";
import { UrlUtil } from "./shared/utils/url.util";
import { TranslateService } from "@ngx-translate/core";
import { SelectUI } from "./shared/constant/select-ui.constant";
import { LanguageService } from "./core/services/language.service";
import { Subscription } from "rxjs";
import {
  InquiryResponse,
  Language,
  SpinnerService,
  ToasterService,
  MessageType,
  ServiceConstant,
  Institution,
  InquiryRequest,
  DataType,
} from "projects/shared-lib/src/public-api";
import { TranslateLanguage } from "./shared/model/translate-language.model";
import { DataStoreService } from "./core/services/data-store.service";
import { LanguageUtil } from "./shared/utils/language.util";
import { BrowserSupportUtil } from "projects/AdminApp/src/app/shared/util/browser-support.util";
import { InstitutionService } from "./core/services/institution.service";
import { ThemeService } from "./core/services/theme.service";
import { ServiceUtil } from "projects/AdminApp/src/app/shared/util/service-util";
import { finalize } from "rxjs/operators";
import { LocalDatabaseService } from "./core/services/local-database.service";
import { UserAppService } from "./core/services/userapp.service";
import { SwPush } from "@angular/service-worker";
import { AppParams } from './shared/model/app-params.model';
import { SurveyUserService } from "./core/services/survey-user.service";
import { EncryptionUtil } from "./shared/utils/encryption-util";
import { ActivatedRoute, Router } from "@angular/router";
import { QuestionListenerUtil } from "./shared/utils/question-listener.util";

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.css"],
})
export class AppComponent implements OnInit, AfterViewInit, OnDestroy {
  private institutionId: number = -1;
  private institutions: Institution[] = [];
  private subscriptions: Subscription[] = [];
  private gotTimeout = false;
  private params: AppParams;
  private userTypeName: string;

  constructor(
    private localStorageUtil: LocalStorageUtil,
    private urlUtil: UrlUtil,
    private languageUtil: LanguageUtil,
    private toasterService: ToasterService,
    private spinnerService: SpinnerService,
    private languageService: LanguageService,
    private dataStoreService: DataStoreService,
    private browserSupport: BrowserSupportUtil,
    public translateService: TranslateService,
    public institutionService: InstitutionService,
    public themeService: ThemeService,
    private serviceUtil: ServiceUtil,
    private localDBService: LocalDatabaseService,
    private userAppService: UserAppService,
    private surveyUserService: SurveyUserService,
    private encryptionUtil: EncryptionUtil,
    public questionListenerUtil: QuestionListenerUtil,
    private swPush: SwPush,
    private router: Router,
    private route: ActivatedRoute
  ) {
    this.setup = this.setup.bind(this);
    this.onError = this.onError.bind(this);
    this.onAdminLanguageSuccess = this.onAdminLanguageSuccess.bind(this);
    this.onLanguageSuccess = this.onLanguageSuccess.bind(this);
    this.initUsageData = this.initUsageData.bind(this);
    this.isNotBrowserSupported = this.isNotBrowserSupported.bind(this);
    this.notificationEvent = this.notificationEvent.bind(this);
  }

  private isNotBrowserSupported() {
    const msj =
      "Your browser might be not compatible with our system, please upgrade or try to use other browser such as Google Chrome or Safari.";
    if (!this.browserSupport.isBrowserCompatible()) {
      // ToDo: Change to to incompatible browsers only
      // this.toasterService.show(MessageType.WARNING, msj);
    }
  }

  private setup(languages: TranslateLanguage[]) {
    
    let categories = (languages.length) ? languages[0].categories : [] 
    this.localStorageUtil.saveCategoryDataTyge(categories.map(c => <DataType>{ id: c.id, description: c.description}));
    let defaultLang = this.localStorageUtil.getLanguage(this.userTypeName);
    if (!this.languageUtil.isValidLang(languages, defaultLang)) {
      defaultLang = SelectUI.DEFAULT_LANG;
    }
    this.localStorageUtil.saveLanguage(this.userTypeName,defaultLang);
    const langCodes = this.languageUtil.mapLanguagesToLangCode(languages);
    this.translateService.addLangs(langCodes);
    this.translateService.setDefaultLang(SelectUI.DEFAULT_LANG);
    this.translateService.use(defaultLang);
  }

  private onError(error) {
    this.toasterService.show(MessageType.SYSTEM_ERROR, error);
  }

  private onAdminLanguageSuccess(response: InquiryResponse<Language>) {
    if (response.operationSuccess) {
      const languages: TranslateLanguage[] = this.languageUtil.mapToTranslateLanguage(
        [...response.returnValues]
      );
      
      this.dataStoreService.setLanguages(languages);
      this.localDBService.deleteAllLanguage();
      this.setup(languages);
    } else if (response.messages.length > 0) {
      this.toasterService.show(
        response.messages[0].type,
        response.messages[0].text
      );
    } else {
      this.onError(ServiceConstant.somethingWentWrongMessage);
    }
  }

  private onLanguageSuccess(response: InquiryResponse<Language>, needUpdate = false) {
    this.spinnerService.hide();
    if (response.operationSuccess) {
      if (!this.dataStoreService.getLanguages().length || needUpdate) {
        console.log("onLanguageSuccess")
        console.log(response)
        const languages: TranslateLanguage[] = this.languageUtil.mapToTranslateLanguage(
          [...response.returnValues]
        );
        console.log(languages)
        this.dataStoreService.setLanguages(languages);
        this.localDBService.deleteAllLanguage();
        this.localDBService.addLanguages(response.returnValues);
        this.setup(languages);
        this.populateUserIdIfMissing(this.userTypeName);
      }
    } else if (response.messages.length > 0) {
      this.toasterService.show(
        response.messages[0].type,
        response.messages[0].text
      );
    } else {
      if (!this.gotTimeout)
        this.onError(ServiceConstant.somethingWentWrongMessage);
    }
  }

  private initUsageData() {
    let usage = this.localStorageUtil.getUsageInfo(this.userTypeName);
    const hasUsage = !!usage;
    if (!hasUsage) {
      usage = new UsageData();
      usage.sessionId = this.localStorageUtil.getSurveyUserId(this.userTypeName);
      this.localStorageUtil.saveUsageInfo(this.userTypeName,usage);
    }
  }

  private setInstitutionTheme() {
    if (this.institutionId > 0) {
      const institution: Institution = this.institutions.find(
        (i) => i.id === this.institutionId
      );
      if (institution) {
        this.localStorageUtil.savePreferedInstitution(this.userTypeName,institution);
        this.themeService.setActiveTheme(institution);
      } else {
        this.localStorageUtil.deletePreferedInstitution(this.userTypeName);
      }
    }
  }

  private notificationEvent({ action, notification }) {}

  getInstitutionsAndSetTheme() {
    const msj = "An error has occurred";
    if (this.institutions.length === 0) {
      this.spinnerService.show();
      this.subscriptions.push(
        this.institutionService
          .getInstitutions(new InquiryRequest())
          .pipe(finalize(() => this.spinnerService.hide()))
          .subscribe((r) => {
            if (r.operationSuccess) {
              this.institutions = r.returnValues;
              this.institutionService.allInstutions$.next(this.institutions);
              this.setInstitutionTheme();
            } else {
              this.toasterService.show(
                msj,
                this.serviceUtil.getErrorMessage(r)
              );
            }
          })
      );
    } else {
      this.setInstitutionTheme();
    }
  }

  ngOnInit() {
    this.localStorageUtil.convertToNewFormat(); //convert old format to new format.
    this.subscriptions.push(
      this.swPush.notificationClicks.subscribe(this.notificationEvent)
    );
  }

  handleInstitutionChange() {
    this.languageService.getInstitutionChangeEmitter().subscribe(async inst => {
      this.institutionId = inst;
      await this.populateGuidelinesDataAsync(inst);
      console.log("Institution changed!!!! Ins: " + inst);
    });
  }

  handleLanDataChange() {
    this.languageService.getlangDataChangeEmitter().subscribe(data => {
      this.onLanguageSuccess(data, true);
      const qParams = this.urlUtil.getQueryParams(window.location.search);
      console.log(qParams)
      this.dataStoreService.setCurrentGuidelines(qParams.lang);
    })
  }

  ngAfterViewInit() {
    //this.handleInstitutionChange();
    this.handleLanDataChange();
    this.populateGuidelinesData();
  }

  async populateGuidelinesDataAsync(instId = null) {

    const qParams = this.urlUtil.getQueryParams(window.location.search);
    const path = this.urlUtil.getBasePath(window.location.pathname);
    this.params = this.urlUtil.getParams(path, window.location.origin);
    this.userTypeName = this.params.categoryName;
    this.localStorageUtil.saveLanguage(this.userTypeName,qParams.lang);
    this.institutionId = this.params.institutionId;
    if(instId) this.institutionId = instId;
    console.log("populateInstId: " + this.institutionId )
    window.sessionStorage.setItem("category", this.params["categoryName"])
    if(path.length <= 1)
    {
      var url = this.urlUtil.getGuidelinesUrl(this.userTypeName);
      this.router.navigate([url] );
    }
    this.localStorageUtil.saveAcceptedDisclaimer
    this.spinnerService.show();
    if (qParams.loggedIn) {
      this.subscriptions.push(
        this.languageService
          .getAdminLanguages()
          .pipe(finalize(() => this.spinnerService.hide()))
          .subscribe(this.onAdminLanguageSuccess, this.onError)
      );
    } else {
      
      console.log("Lang fetch: " + this.institutionId)
      try{
        var langResp = await this.languageService
          .getUserLanguages(this.userTypeName, this.institutionId).toPromise();
          this.onLanguageSuccess(langResp);
      } catch(err) {
        this.onError(err);
      }
          
          //.pipe(finalize(() => this.spinnerService.hide()))
          //.subscribe(this.onLanguageSuccess, this.onError)
      this.initUsageData();
    }
    this.getInstitutionsAndSetTheme();
    this.isNotBrowserSupported();
  }

  populateGuidelinesData(instId = null) {

    const qParams = this.urlUtil.getQueryParams(window.location.search);
    const path = this.urlUtil.getBasePath(window.location.pathname);
    this.params = this.urlUtil.getParams(path, window.location.origin);
    this.userTypeName = this.params.categoryName;
    this.localStorageUtil.saveLanguage(this.userTypeName,qParams.lang);
    this.institutionId = this.params.institutionId;
    if(instId) this.institutionId = instId;
    window.sessionStorage.setItem("category", this.params["categoryName"])
    if(path.length <= 1)
    {
      var url = this.urlUtil.getGuidelinesUrl(this.userTypeName);
      this.router.navigate([url] );
    }
    this.localStorageUtil.saveAcceptedDisclaimer
    this.spinnerService.show();
    if (qParams.loggedIn) {
      this.subscriptions.push(
        this.languageService
          .getAdminLanguages()
          .pipe(finalize(() => this.spinnerService.hide()))
          .subscribe(this.onAdminLanguageSuccess, this.onError)
      );
    } else {
      this.gotTimeout = false;
      setTimeout(() => {
        if (!this.dataStoreService.getLanguages().length) {
          this.localDBService.getAllLanguage().then((languages) => {
            if (languages.length) {
              this.onLanguageSuccess(<InquiryResponse<Language>>{
                operationSuccess: true,
                returnValues: languages,
              });
            }
            this.gotTimeout = true;
          });
        }
      }, 10000);
      
      console.log("Lang fetch: " + this.institutionId)
      this.subscriptions.push(
        this.languageService
          .getUserLanguages(this.userTypeName, this.institutionId)
          //.pipe(finalize(() => this.spinnerService.hide()))
          .subscribe(this.onLanguageSuccess, this.onError)
      );
      this.initUsageData();
    }
    this.getInstitutionsAndSetTheme();
    this.isNotBrowserSupported();
    this.localDBService.dbReady$.subscribe((ready) => {
      if (ready) {
        this.userAppService.checkPendingUsageData();
      }
    });
  }

  ngOnDestroy() {
    this.subscriptions.forEach((s) => s.unsubscribe());
  }

  async populateUserIdIfMissing(categoryName: string){
    var surveyId = this.localStorageUtil.getSurveyId(this.userTypeName);
    if( surveyId !== undefined && surveyId !== null )
    {
      //check whether it is encrypted if not encrpt existing ones.
      if( surveyId.indexOf('@') !== -1 )
      {
        surveyId = await this.encryptionUtil.encrypt(surveyId.trim().toUpperCase());
        this.localStorageUtil.saveSurveyId(this.userTypeName,surveyId);
      }

      let categoryId: number = this.localStorageUtil.getCategoryByName(categoryName);
      this.spinnerService.show()
      this.surveyUserService.getSurveyByEmail(surveyId,categoryId)
      .pipe(finalize(() => this.spinnerService.hide()))
      .subscribe(res => {
        if (res.returnValues.length > 0) {
          this.localStorageUtil.saveSurveyUserId(this.userTypeName,res.first.id.toString());
        }
        else
        {
          this.toasterService.show(MessageType.INFORMATIONAL,'Email does not exist.');
        }
      });
    }
  }
}
