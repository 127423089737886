export class PathwayStatus {
  constructor(
    public id: number,
    public description: string,
    public rowStatus: boolean,
    public createdBy: string,
    public createdDate: Date,
    public updatedBy: string,
    public updatedDate: Date,
    public modelAction: number
  ) {}
}
