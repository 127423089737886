import { Injectable } from "@angular/core";
import { TranslateLanguage } from "../../shared/model/translate-language.model";
import { Observable, of, BehaviorSubject } from "rxjs";
import { LanguageUtil } from "../../shared/utils/language.util";
import { Decision } from "projects/shared-lib/src/public-api";

@Injectable({ providedIn: "root" })
export class DataStoreService {
  private languages: TranslateLanguage[] = [];
  public languages$ = new BehaviorSubject<TranslateLanguage[]>(this.languages);
  public languageChanged$ = new BehaviorSubject<string>(null);
  public currentGuidelines$ = new BehaviorSubject<Decision[]>([]);

  constructor(private languageUtil: LanguageUtil) {}

  setCurrentGuidelines(langCode: string) {
    const guidelines: Decision[] = this.languageUtil.findGuidelinesByLang(
      this.languages,
      langCode
    );
    console.log("setCurrentGuidelines")
    console.log(this.languages)
    console.log(guidelines)
    this.currentGuidelines$.next(guidelines);
  }

  setLanguages(languages: TranslateLanguage[]) {
    console.log("setLanguages")
    this.languages = languages;
    this.languages$.next(this.languages);
  }

  getLanguages() {
    return this.languages;
  }

  getTranslations(langCode: string): Observable<{}> {
    let translations = {};
    if (this.languages.length > 0) {
      this.setCurrentGuidelines(langCode);
      translations = this.languageUtil.findTranslationByLang(
        this.languages,
        langCode
      );
    }
    return of(translations);
  }
}

/*
#languages data structure
[{
  id,
  langugeCode,
  languageDesc,
  translations,
  guidelines
}]
*/
