import { Injectable } from "@angular/core";
import { HttpHeaders, HttpErrorResponse } from "@angular/common/http";
import { throwError } from "rxjs";
import { environment } from "projects/AdminApp/src/environments/environment";
import {
  LocalStorageUtil,
  InquiryResponse,
  ToasterService,
  MessageType,
  SpinnerService
} from "projects/shared-lib/src/public-api";
import { AccountService } from "projects/AdminApp/src/app/shared/service/account.service";

@Injectable({ providedIn: "root" })
export class ServiceUtil {
  readonly rootUrl = `${environment.baseUrl}`;

  constructor(
    private localStorageUtil: LocalStorageUtil,
    private accountService: AccountService,
    private spinnerService: SpinnerService,
    private toasterService: ToasterService
  ) {
    this.handleError = this.handleError.bind(this);
    this.handleResponse = this.handleResponse.bind(this);
    this.onError = this.onError.bind(this);
    //this.onSuccess = this.onSuccess.bind(this);
  }

  public newExpirationDate(expiresIn: number): Date {
    return new Date(new Date().getTime() + expiresIn);
  }

  public newExpirationDuration(expDate: Date): number {
    return new Date(expDate).getTime() - new Date().getTime();
  }

  private isUnauthorizedRequest(
    errorStatus: number,
    errorStatusText: string
  ): boolean {
    const errorCode = 401;
    const unauthorizedText = "Unauthorized";
    return errorStatus === errorCode && errorStatusText === unauthorizedText;
  }

  private isUnknownError(error: any): boolean {
    return error === null || error.error === null ? true : false;
  }

  getCommonHeaders() {
    return {
      headers: new HttpHeaders({
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
        "Accept-Language": "en-US",
      }),
    };
  }

  getCommonAdminHeadersWithAuth() {
    const admin = this.localStorageUtil.getAdmin();
    const token = admin ? admin.getToken() : "";
    return {
      headers: new HttpHeaders({
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
        "Accept-Language": "en-US",
        "X-Auth-Token": token,
      }),
    };
  }

  getNonJSONContentAuthHeader() {
    const admin = this.localStorageUtil.getAdmin();
    const token = admin ? admin.getToken() : "";
    return {
      headers: new HttpHeaders({
        "Access-Control-Allow-Origin": "*",
        "Accept-Language": "en-US",
        "X-Auth-Token": token,
      }),
    };
  }

  handleError(errorResp: HttpErrorResponse) {
    let errorMessage = "An unknown error occurred";
    const isUnknown = this.isUnknownError(errorResp.error);
    const isUnauthorized = this.isUnauthorizedRequest(
      errorResp.status,
      errorResp.statusText
    );
    if (isUnknown && isUnauthorized) {
      errorMessage = "The session has expired";

      this.accountService.clearAccount();
    } else if (isUnknown) {
      errorMessage = errorMessage;
    }
    return throwError(errorMessage);
  }

  handleResponse(response: InquiryResponse<any>) {}

  getErrorMessage<T>(response: InquiryResponse<T>) {
    let errors: string = "";
    if (!response.operationSuccess) {
      response.messages.forEach((message) => {
        errors += message.text + " ";
      });
    }
    return errors;
  }

  public onError(error) {
    this.spinnerService.hide();
    this.toasterService.show(MessageType.SYSTEM_ERROR, error);
  }
}
