
import { BaseClass } from "./decision.model";
import { LanguageText } from "./language-text.model";

export class QuestionOption extends BaseClass {
    public description: string;
    public variable?: string;
    public questionId: number;
    public modelAction: number;
    public code: string;
    public isTableOption: boolean;
    public textList: LanguageText[];
}
