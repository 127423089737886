export class InstitutionByVisitor {
  public institutionId: number;
  public visitorId: number;
  public rowStatus: boolean;
  public createdBy: string;
  public createdDate: Date;
  public updatedBy: string;
  public updatedDate: Date;
  public modelAction: number;
}
