import { Injectable } from "@angular/core";
import { LocalStorageUtil } from "./local-storage.util";
import {
  UrlSegment,
  PRIMARY_OUTLET,
  UrlTree,
  Router,
  ActivatedRoute,
} from "@angular/router";
import { AppParams } from "../model/app-params.model";
import { AppQueryParams } from "../model/app.query-params.model";
import { SelectUI } from "../constant/select-ui.constant";
import { TranslateService } from "@ngx-translate/core";

@Injectable({ providedIn: "root" })
export class UrlUtil {
  private guidelineSegment = "guidelines";
  private surveySegment = "survey";
  constructor(
    private router: Router,
    private localStorageUtil: LocalStorageUtil,
    public translateService: TranslateService,
    private route: ActivatedRoute
  ) {
    this.existUrlSegment = this.existUrlSegment.bind(this);
  }

  private existUrlSegment(urlSegments: UrlSegment[]): boolean {
    const lastSegment = urlSegments[urlSegments.length - 1];
    const eduSegment = urlSegments[0] ? urlSegments[0] : null;
    return (
      lastSegment.path === this.guidelineSegment ||
      eduSegment.path === this.surveySegment
    );
  }

  getBasePath(pathname: string): string {
    const guidelines = "guidelines";
    const defaultPath = "/";
    if (pathname.includes(guidelines)) {
      const splitPath = pathname.split(guidelines);
      const [basePath] = splitPath;
      return basePath;
    }
    if(pathname.includes("survey")) {
      return pathname;
    }
    return defaultPath;
  }

  getParams(pathname: string, origin: string): AppParams {
    const splitPath = pathname.split("/").filter(element=>element);
    const originParts = origin.split(".").filter(element=>element);
    console.log(splitPath);
    console.log(originParts);
    let categoryName = "";
    let institutionId: number  =0;
    const params = new AppParams();
    if(splitPath.find(s => s == "survey")) {
      categoryName = this.findCategoryName("institution",splitPath,originParts, 1);
      institutionId = this.findInstitutionId("institution", splitPath);
      params.categoryName = categoryName;
      params.institutionId = institutionId;
      this.localStorageUtil.setIsInstitutionURL(categoryName,institutionId > 0);
      console.log(params);
      return params;
    }
    categoryName = this.findCategoryName("institutions",splitPath,originParts, 0);
    institutionId = this.findInstitutionId("institutions", splitPath);
    if(institutionId === 0)
    {
      var inst = this.localStorageUtil.getPreferedInstitution(categoryName);
      if(inst !== null)
        institutionId = inst.id;
    }
    this.localStorageUtil.setIsInstitutionURL(categoryName,institutionId > 0);
    params.categoryName = categoryName.toLowerCase();
    params.institutionId = institutionId;
    console.log(params);
    return params;
  }

  findInstitutionId(tag: string, splitPath: string[]): number
  {
    var startIdx = 0;
    while(startIdx < splitPath.length)
    {
      if(splitPath[startIdx].toLowerCase() === tag)
      {
        return +splitPath[startIdx+1];
      }
      startIdx++;
    }
    return 0;
  }

  /**
   * @param tag "instituitons" or "institution"
   * @param splitPath 
   * @param originParts 
   * @param idx
   * @returns 
   */
  findCategoryName(tag: string,splitPath: string[], originParts: string[], idx: number): string
  {

    if(idx < splitPath.length && splitPath[idx].toLowerCase() !== tag)
    {
      return splitPath[idx];
    }
    if(originParts.length === 3)
    {
      //check if originParts[0] http or https
      var startIdx = originParts[0].indexOf("//");
      if(startIdx == -1)
        return  originParts[0];
      console.log(originParts[0].substring(startIdx + 2));

      return originParts[0].substring(startIdx + 2);
    }
    else
    {
      console.log("No category Name");
    }
    return "";
  }

  getQueryParams(searchQuery: string): AppQueryParams {
    const qParams = new URLSearchParams(searchQuery);
    const lang = qParams.has("lang")
      ? qParams.get("lang")
      : SelectUI.DEFAULT_LANG;
    const loggedIn = qParams.has("loggedIn")
      ? JSON.parse(qParams.get("loggedIn"))
      : false;

    return new AppQueryParams(loggedIn, lang.toLowerCase());
  }

  updateLangParam(langCode: string) {
    const urlTree = this.router.parseUrl(this.router.url);
    const url = urlTree.root.children["primary"].segments
      .map((it) => it.path)
      .join("/");
    const queryParams = new URLSearchParams(window.location.search);
    queryParams.set("lang", langCode);
    history.replaceState(null, null, `/${url}?` + queryParams.toString());
  }

  isGuidelineRoute(tree: UrlTree): boolean {
    const primary = tree.root.children[PRIMARY_OUTLET];
    return primary.segments ? this.existUrlSegment(primary.segments) : false;
  }

  getGuidelinesUrl(userTypeName: string): string {
    let url = "/edu-family/guidelines";
    const params = this.localStorageUtil.getGuidelinesParams(userTypeName);

    if (params?.institutionId) {
      url = `/${userTypeName}/institutions/${params["institutionId"]}/guidelines`;
    }
    else
    {
      url = `/${userTypeName}/guidelines`;
    }
    return url;
  }

  goToGuidelines(userTypeName: string) {
    const url = this.getGuidelinesUrl(userTypeName);
    const queryParams = { lang: this.translateService.currentLang };
    this.router.navigate([url], { queryParams });
  }
}
