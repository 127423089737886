import { Injectable } from '@angular/core';
import { HiLowRange } from '../model/hilow.model'

@Injectable({ providedIn: "root" })
export class ParseScoreUtility
{
    maxHighValue = 1500;
    parseScore(score: string): HiLowRange
    {
        const reg1: RegExp = /^(<|>)(=)?(\s)*[0-9]*(\s)*$/;// < number or > number or <= number or >= number
        const reg2: RegExp = /^[0-9]+(\s)*[-]{1}(\s)*[0-9]+$/;//Eg: 10 - 20 or 10-20
        const reg3: RegExp = /^[0-9]+$/;
        if(reg1.test(score))
        {
            if(score[0] == '<')
                return this.lessThanOrEqual(score);
            else
                return this.greaterThanOrEqual(score);
        }
        else if(reg2.test(score))
        {
            return this.rangeBased(score);
        }
        else if(reg3.test(score))
        {
            let obj = new HiLowRange();
            obj.low = +score;
            obj.high = obj.low;
            return obj;
        }
        return null;
    }

    greaterThanOrEqual(score: string): HiLowRange
    {
        let result = new HiLowRange();
        let i = 1;
        if(score[i] == '=')
            i = 2;
        score = score.substring(i);
        let numericData = +score;
        result.low = (i == 1) ? numericData + 1 : numericData;//i==2 when >= 
        result.high = this.maxHighValue;
        return result;
    }

    lessThanOrEqual(score: string): HiLowRange
    {
        let result = new HiLowRange();
        let i = 1;
        if(score[i] == '=')
            i = 2;
        score = score.substring(i);
        let numericData = +score;
        result.high = (i == 1) ? numericData - 1 : numericData;//i==2 when >= 
        result.low = 0;
        return result;
    }

    rangeBased(score: string): HiLowRange
    {
        let data = score;
        data = data.replace(/\s/g, "");
        let array = data.split('-');
        if(array.length == 2)
        {
            let result = new HiLowRange();
            result.low = +array[0];
            result.high = +array[1];
            return result;
        }
        return null;
    }
}