import { Pipe, PipeTransform } from "@angular/core";
import { Institution } from "projects/shared-lib/src/public-api";

@Pipe({ name: "showAfterHourCare" })
export class ShowAfterHourCarePipe implements PipeTransform {
  constructor() {}

  private findInstitution(institutions: Institution[], institutionId: number): Institution {
    return institutions.find((institution) => institution.id === institutionId);
  }

  private showAfterHourCare(institutions: Institution[], institutionId: number): boolean {
    const foundInstitution = this.findInstitution(institutions, institutionId);
    if (foundInstitution) {
      return foundInstitution.showChildrenInstitutions
    } else {
      return false;
    }
  }

  transform(institutions: Institution[], institutionId: number): boolean {
    if (!institutions.length) return false;
    if (!institutionId) return false;
    return this.showAfterHourCare(institutions, institutionId);
  }
}
