import { ChildrenModelBase } from './children-base.model';
import { Unit } from './unit.model';

export class Facility extends ChildrenModelBase {
    name: string;
    contactName: string;
    contactPhone: string;
    email: string;
    emailSubject: string;
    facilityTypeId: number;
    institutionId: number;
    variable: string;
    units: Unit[] = [];
}
