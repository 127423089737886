import { Pipe, PipeTransform } from "@angular/core";
import { TranslateLanguage } from "../model/translate-language.model";
import { LanguageUtil } from "../utils/language.util";
import { GuidelineUtil } from "../utils/guideline.util";
import { Decision } from "projects/shared-lib/src/public-api";

@Pipe({ name: "filterSupportedLangByCode" })
export class FilterSupportedLangByCodePipe implements PipeTransform {
  constructor(
    private languageUtil: LanguageUtil,
    private guidelineUtil: GuidelineUtil
  ) {}

  private isGuidelineHasCompletedPathway(guidelineId: number) {
    return (guideline: Decision) =>
      guideline.id === guidelineId &&
      this.guidelineUtil.hasCompletedPathways(guideline);
  }

  private someGuidelineHasCompletedPathways(
    guidelines: Decision[],
    guidelineId: number
  ) {
    return guidelines.some(this.isGuidelineHasCompletedPathway(guidelineId));
  }

  private filterSupportedLangById(
    translateLanguages: TranslateLanguage[],
    guidelineId: number
  ): TranslateLanguage[] {
    return translateLanguages.filter((translateLanguage) => {
      return this.someGuidelineHasCompletedPathways(
        translateLanguage.guidelines,
        guidelineId
      );
    });
  }

  private filterSupportedLangByCode(
    translateLanguages: TranslateLanguage[],
    guidelineId: number,
    langCode: string
  ) {
    const filteredTranslateLang = this.languageUtil.filterTranslationsByLangCode(
      translateLanguages,
      langCode
    );
    const supportedLanguages = this.filterSupportedLangById(
      filteredTranslateLang,
      guidelineId
    );
    return this.languageUtil
      .mapLanguagesToLangCode(supportedLanguages)
      .reverse();
  }

  transform(
    translateLanguages: TranslateLanguage[],
    guidelineId: number,
    langCode: string
  ): string[] {
    return this.filterSupportedLangByCode(
      translateLanguages,
      guidelineId,
      langCode
    );
  }
}
