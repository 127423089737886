import { UsageDetail } from "./usage-detail.model";

export class Usage {
  public id: number;
  public deviceId: string;
  public sessionId: string;
  public startDate: Date;
  public endTime: Date;
  public latitude: number;
  public longitude: number;
  public referralSource: string;
  public categoryName: string;
  public categoryId: number;
  public institutionId: number;
  public usageDetails: UsageDetail[];
  public modelAction: number;
  public rowStatus: boolean;
}
