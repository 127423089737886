import { Component, Input, Output, EventEmitter } from "@angular/core";
import { Toast } from "./toast.interface";
import { MessageType } from "projects/shared-lib/src/lib/constant/message-type.constant";

@Component({
  selector: "app-toaster",
  template: `
    <div
      class="toast bg-white color-dark"
      [style.bottom.px]="i * 100"
      [ngSwitch]="toast.msgType"
    >
      <img
        *ngSwitchCase="messageType.INFORMATIONAL"
        src="../../../../assets/done-black-24px.svg"
      />
      <img
        *ngSwitchCase="messageType.WARNING"
        src="../../../../assets/warning-black-24px.svg"
      />
      <img
        *ngSwitchCase="messageType.BUSINESS_ERROR"
        src="../../../../assets/error-black-24px.svg"
      />
      <img
        *ngSwitchCase="messageType.SYSTEM_ERROR"
        src="../../../../assets/error-black-24px.svg"
      />
      <img
        *ngSwitchCase="messageType.VALIDATION"
        src="../../../../assets/info-black-24px.svg"
      />
      <img
        *ngSwitchCase="messageType.INTERNAL"
        src="../../../../assets/error-black-24px.svg"
      />
      <img
        *ngSwitchCase="messageType.DEBUG"
        src="../../../../assets/help-black-24px.svg"
      />

      <label id="notification_msg_pop-up" class="toast-text">{{ toast.text }}</label>
      <a class="close" (click)="remove.emit(i)">&times;</a>
    </div>
  `,
  styleUrls: ["./toaster.component.css"]
})
export class ToasterComponent {
  messageType = MessageType;
  @Input() toast: Toast;
  @Input() i: number;

  @Output() remove = new EventEmitter<number>();
}
