import { Injectable } from "@angular/core";
import {
  CanActivate,
  Router,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
} from "@angular/router";
import { LocalStorageUtil } from "../utils/local-storage.util";
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: "root",
})
export class DefaultPathGuard implements CanActivate {
  constructor(
    private router: Router,
    private localStorageUtil: LocalStorageUtil,
    private translateService: TranslateService
  ) {}

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    if(next.queryParams && next.queryParams.loggedIn){
      return true;
    }
    if (next.params && next.params.category === "edu-family" && this.localStorageUtil.getPreferedInstitution(next.params.category)) {
        var queryParams: { lang; loggedIn?} = { lang: this.translateService.currentLang };
        if(next.queryParams && next.queryParams.loggedIn){
          queryParams.loggedIn = next.queryParams.loggedIn;
        }
        const inst = this.localStorageUtil.getPreferedInstitution(next.params.category);
        const url = `edu-family/institutions/${inst.id}/guidelines`;
        this.router.navigate([url], {queryParams} );
    }
    else if (next.params && next.params.category.toLowerCase() === "vipbasic") {
      if (!this.localStorageUtil.getSurveyId(next.params.category)) {
        let url = "/survey/vipbasic";
        url = (next.params.institutionId) ? `/survey/vipbasic/institution/${next.params.institutionId}` : url;
        var queryParams: { lang; loggedIn?} = { lang: this.translateService.currentLang };
        if(next.queryParams && next.queryParams.loggedIn){
          queryParams.loggedIn = next.queryParams.loggedIn;
        }
        this.router.navigate([url],  { queryParams } );
        return false;
      }
    }
    return true;
  }
}

