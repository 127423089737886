import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { HttpClientModule } from "@angular/common/http";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { NgSelectModule } from "@ng-select/ng-select";
import { NgOptionHighlightModule } from "@ng-select/ng-option-highlight";
import { MaterialModule } from "./material.module";
import { MAT_FORM_FIELD_DEFAULT_OPTIONS } from "@angular/material/form-field";
import { SanitizerPipe } from "./pipes/sanitizer.pipe";
import { TranslateModule } from "@ngx-translate/core";
import { FilterByLangCodePipe } from "./pipes/filter-by-lang-code.pipe";
import { HasCompletedPathwaysPipe } from "./pipes/has-completed-pathways.pipe";
import { FilterSupportedLangByCodePipe } from "./pipes/filter-supported-lang-by-code.pipe";
import { FilterSupportedLangPathByCodePipe } from "./pipes/filter-supported-lang-path-by-code.pipe";
import { FindLangByCodePipe } from "./pipes/find-lang-by-code.pipe";
import { HouseholdHeaderComponent } from './components/household-header/household-header.component';
import { FilterFacilitiesByInstPipe } from './pipes/filter-facilities-by-inst.pipe';
import { FilterInstitutionsByTypePipe } from './pipes/filter-institutions-by-type.pipe';
import { ShowAfterHourCarePipe } from "./pipes/show-after-hour-care.pipe";
import { filterValidInstMemberOptionsPipe } from "./pipes/filter-valid-inst-member-opts.pipe";
import { SelectLangModal } from "./components/select-lang-modal/select-lang-modal.component";

@NgModule({
  imports: [
    CommonModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    NgSelectModule,
    NgOptionHighlightModule,
    MaterialModule,
    TranslateModule,
  ],
  exports: [
    CommonModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    NgSelectModule,
    NgOptionHighlightModule,
    MaterialModule,
    SanitizerPipe,
    FilterByLangCodePipe,
    HasCompletedPathwaysPipe,
    FilterSupportedLangByCodePipe,
    FilterSupportedLangPathByCodePipe,
    FindLangByCodePipe,
    FilterFacilitiesByInstPipe,
    FilterInstitutionsByTypePipe,
    ShowAfterHourCarePipe,
    filterValidInstMemberOptionsPipe,
    HouseholdHeaderComponent,
    SelectLangModal,
    TranslateModule,
  ],
  declarations: [
    SanitizerPipe,
    FilterByLangCodePipe,
    HasCompletedPathwaysPipe,
    FilterSupportedLangByCodePipe,
    FilterSupportedLangPathByCodePipe,
    FindLangByCodePipe,
    FilterFacilitiesByInstPipe,
    FilterInstitutionsByTypePipe,
    ShowAfterHourCarePipe,
    filterValidInstMemberOptionsPipe,
    HouseholdHeaderComponent,
    SelectLangModal
  ],
  providers: [
    {
      provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
      useValue: { appearance: "fill" },
    },
  ],
})
export class SharedModule {}
