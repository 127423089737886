import { Component, OnInit } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { MatDialogRef } from '@angular/material/dialog';
import { DataStoreService } from "../../../core/services/data-store.service";
import { LanguageService } from "../../../core/services/language.service";
import { TranslateLanguage } from "../../model/translate-language.model";

@Component({
    selector: 'select-lang-modal',
    templateUrl: './select-lang-modal.component.html',
    styleUrls: ['./select-lang-modal.component.css']
})
export class SelectLangModal implements OnInit{

    public languages: TranslateLanguage[] = [];
    public langControl: FormControl;
    public form: FormGroup;

    constructor(public dialogRef: MatDialogRef<SelectLangModal>, private languageService: LanguageService, public dataStoreService: DataStoreService){
     }
     
     ngOnInit() {
         this.langControl = new FormControl('', [Validators.required]);
         this.form = new FormGroup({
            langControl: this.langControl
         })
        this.dataStoreService.languages$.subscribe(languages => {
            languages.forEach(l => this.languages.push(l));
        });
     }

    onSubmit() {
        if(this.langControl.invalid) {
            this.langControl.markAsTouched();
            return;
        }
        this.dialogRef.close(this.langControl.value);
    }
    
    public onCancel(){
        this.dialogRef.close(1);
    }
    
}