export class Account {
  constructor(
    public username: string,
    private _token: string,
    private _expiresIn: number
  ) {}

  getToken(): string {
    return this._token;
  }

  getExpiration(): number {
    return this._expiresIn;
  }
}
