
import { ChildrenModelBase } from "./children-base.model";
import { TableFilter } from "./table-filter.model";


export class TableFilterGroup extends ChildrenModelBase {
  public surveyQuestionId: number;
  public tableName: string;
  public tableFilters: TableFilter[];
}
