<mat-nav-list>
  <mat-card class="mat-card bg-primary">
    <div class="header-image-div">
      <img class="header-image" alt="" [src]="themeService.imageURL$ | async" />
    </div>
  </mat-card>
  <br />
  <a
    mat-list-item
    routerLinkActive="active"
    class="color-primary"
    [routerLink]="guidelinesUrl()"
    [queryParams]="{ lang: translateService.currentLang }"
    >{{ "Guidelines" | translate }}</a
  >
  <a
    *ngIf="surveyLink"
    mat-list-item
    routerLinkActive="active"
    class="color-primary"
    [routerLink]="[surveyLink]"
    [queryParams]="{ lang: translateService.currentLang }"
    >{{ "Registration" | translate }}</a
  >
  <a
    *ngIf="false"
    mat-list-item
    routerLinkActive="active"
    class="color-primary"
    [routerLink]="['/about']"
    [queryParams]="{ lang: translateService.currentLang }"
    >{{ "About" | translate }}</a
  >
  <a
    *ngIf="false"
    mat-list-item
    routerLinkActive="active"
    class="color-primary"
    [routerLink]="['/terms']"
    [queryParams]="{ lang: translateService.currentLang }"
    >{{ "Terms of use" | translate }}</a
  >
  <a
    mat-list-item
    routerLinkActive="active"
    class="color-primary"
    target="_blank"
    [href]="[ privacyLink ]"
    >{{ "Privacy Policy" | translate }}</a
  >
</mat-nav-list>
