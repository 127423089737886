export class TableType {
  public static DECISION = 1;
  public static PATHWAY = 2;
  public static QUESTION = 3;
  public static OPTION = 4;
  public static TREATMENT = 5;
  public static SURVEY_QUESTION = 6;
  public static TYPE_INFORMATION = 7;
  public static SURVEY_QUESTION_OPTION = 8;
}
