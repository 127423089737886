import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";

import { Account } from "../../../../../shared-lib/src/public-api";
import { Router } from "@angular/router";
import { LocalStorageUtil } from "projects/shared-lib/src/public-api";

@Injectable({ providedIn: "root" })
export class AccountService {
  private account$ = new BehaviorSubject<Account>(null);
  private tokenExpTimer: any = null;

  constructor(
    private router: Router,
    private localStorageUtil: LocalStorageUtil
  ) {}

  getAccount(): BehaviorSubject<Account> {
    return this.account$;
  }

  setAccount(account: Account): void {
    this.account$.next(account);
  }

  clearAccount() {
    this.setAccount(null);
    this.localStorageUtil.deleteAdmin();
    this.router.navigate(["/auth"]);

    if (this.tokenExpTimer) {
      clearTimeout(this.tokenExpTimer);
    }
    this.tokenExpTimer = null;
  }

  initTokenExpTimer(duration: number) {
    this.tokenExpTimer = setTimeout(() => {
      this.clearAccount();
    }, duration);
  }
}
