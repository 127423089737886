import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { catchError, tap } from "rxjs/operators";

import { ServiceUtil } from "../../shared/utils/service-util";

import {
  InquiryRequest,
  InquiryResponse,
  Institution,
} from "../../../../../shared-lib/src/public-api";
import { of, BehaviorSubject } from 'rxjs';
@Injectable()
export class InstitutionService {
  private institutionUrl = `${this.serviceUtil.rootUrl}/institution`;
  public allInstutions$: BehaviorSubject<Institution[]> = new BehaviorSubject<Institution[]>([]);

  constructor(private serviceUtil: ServiceUtil, private http: HttpClient) {
    this.serviceUtil = serviceUtil;
    this.http = http;
    this.handleGetInstitutionsResponse = this.handleGetInstitutionsResponse.bind(
      this
    );
  }

  private handleGetInstitutionsResponse(
    response: InquiryResponse<Institution>
  ) {}

  getInstitutions(data: InquiryRequest) {
    const url = `${this.institutionUrl}/fetchAll`;
    return this.http
      .post<InquiryResponse<Institution>>(
        url,
        data,
        this.serviceUtil.getCommonHeaders()
      )
      .pipe(
        catchError(this.serviceUtil.handleError),
        tap(this.handleGetInstitutionsResponse)
      );
  }

}