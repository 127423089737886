import { Injectable } from "@angular/core";
import { ServiceUtil } from "../../shared/utils/service-util";
import { HttpClient } from "@angular/common/http";
import { EduFamilySurvey } from "projects/shared-lib/src/lib/model/edu-family-survey.model";
import { catchError, tap } from "rxjs/operators";
import { Observable } from "rxjs";
import { InquiryResponse } from "projects/shared-lib/src/public-api";

@Injectable()
export class EduFamilySurveyService {
  private eduSurveyUrl = `${this.serviceUtil.rootUrl}/edufamilysurvey`;

  constructor(private serviceUtil: ServiceUtil, private http: HttpClient) {
    this.handleEduFamilyResponse = this.handleEduFamilyResponse.bind(this);
  }

    private handleEduFamilyResponse(response: InquiryResponse<EduFamilySurvey>) {}

  saveSurveys(surveys: { eduFamilySurveys: EduFamilySurvey[]; skipUniqueHouseHold: boolean }) {
    const url = `${this.eduSurveyUrl}/save`;
    return this.http
      .post<InquiryResponse<any>>(
        url,
        surveys,
        this.serviceUtil.getCommonHeaders()
      )
      .pipe(
        catchError(this.serviceUtil.handleError),
        tap(this.handleEduFamilyResponse)
      );
  }

  loadSurveyByHouseholdId(
    houdseHoldId: string
  ): Observable<InquiryResponse<EduFamilySurvey>> {
    const url = `${this.eduSurveyUrl}/fetchByHouseHoldId`;
    return this.http
      .post<InquiryResponse<EduFamilySurvey>>(
        url,
        {
          houseHoldId: houdseHoldId,
        },
        this.serviceUtil.getCommonHeaders()
      )
      .pipe(
        catchError(this.serviceUtil.handleError),
        tap(this.handleEduFamilyResponse)
      );
  }

  loadSurveyByEmail(
    email: string
  ): Observable<InquiryResponse<EduFamilySurvey>> {
  const url = `${this.eduSurveyUrl}/fetchByEmail`;
  return this.http
    .post<InquiryResponse<EduFamilySurvey>>(
      url,
      {
        email: email,
      },
      this.serviceUtil.getCommonHeaders()
    )
    .pipe(
      catchError(this.serviceUtil.handleError),
      tap(this.handleEduFamilyResponse)
    );
  }

  searchByLastName(
    lastName: string,
    facilityId: number
  ): Observable<InquiryResponse<EduFamilySurvey>> {
  const url = `${this.eduSurveyUrl}/FetchByLastName`;
  return this.http
    .post<InquiryResponse<EduFamilySurvey>>(
      url,
      {
        lastName: lastName,
        facilityId: facilityId
      },
      this.serviceUtil.getCommonHeaders()
    )
    .pipe(
      catchError(this.serviceUtil.handleError),
      tap(this.handleEduFamilyResponse)
    );
  }

}
