<div class="color-primary">
  <h2 mat-dialog-title>{{ "Disclaimer" | translate }}</h2>
</div>
<mat-dialog-content class="mat-typography">
  <div [innerHTML]="data.content | sanitizer: 'html'"></div>
</mat-dialog-content>
<mat-dialog-actions>
  <button mat-flat-button color="primary" [mat-dialog-close]="true">
    {{ "Accept" | translate }}
  </button>
</mat-dialog-actions>
<br />
