import { InstitutionByVisitor } from "./institution-by-visitor.model";

export class Visitor {
  public id: number;
  public username: string;
  public rowStatus: boolean;
  public createdBy: string;
  public createdDate: Date;
  public updatedBy: string;
  public updatedDate: Date;
  public modelAction: number;
  public institutionsByVisitor: InstitutionByVisitor[];
}
