import { Injectable } from "@angular/core";
import { Toast } from "./toast.interface";
import { MessageType } from "projects/shared-lib/src/public-api";
import { Observable, BehaviorSubject } from "rxjs";
import { filter } from "rxjs/operators";

@Injectable({ providedIn: "root" })
export class ToasterService {
  subject: BehaviorSubject<Toast>;
  toast$: Observable<Toast>;

  constructor() {
    this.subject = new BehaviorSubject<Toast>(null);
    this.toast$ = this.subject
      .asObservable()
      .pipe(filter(toast => toast !== null));
  }

  show(msgType: MessageType, text: string) {
    this.subject.next({ msgType, text });
  }
}
