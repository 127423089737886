import { Injectable } from "@angular/core";

import { UsageData } from "../model/usage-data.model";

import {
  LocalStorageItem,
  Institution,
  DataType,
  InquiryRequest,
} from "projects/shared-lib/src/public-api";
import { BehaviorSubject } from "rxjs";
import { Params } from "@angular/router";
import { SelectUI } from "../constant/select-ui.constant";
import { StorageUserType } from "projects/shared-lib/src/lib/model/storage-user-type";
import { EncryptionUtil } from "./encryption-util";
import { CategoryService } from "../../core/services/category.service";

@Injectable({ providedIn: "root" })
export class LocalStorageUtil {
  public preferredInstitutionChanged$: BehaviorSubject<
    Institution
  > = new BehaviorSubject<Institution>(null);

  constructor(private encryptionUtil: EncryptionUtil, private categoryService: CategoryService) {}

  getCategory(){
    var category = window.sessionStorage.getItem("category");
    if(category === null || category === undefined || category === "")
    {
      return null;
    }
    return category;
  }
  getUserTypeByName(name: string, createIfNotExist: boolean): StorageUserType{
    if(name === undefined)
    {
      var name = window.sessionStorage.getItem("category");
      if(name === null)
        return null;
    }
    var userTypeName = name.toLowerCase();
    var categoryMap = this.getAllUserTypes();
    if(categoryMap.has(userTypeName))
      return categoryMap.get(userTypeName);
    if(createIfNotExist)
    {
      var newUserType = new StorageUserType();
      newUserType.name = userTypeName;
      newUserType.language = SelectUI.DEFAULT_LANG;
      newUserType.acceptedDisclaimer = false;
      newUserType.usage = new UsageData();
      newUserType.variableList = new Map<string,string>();
      categoryMap.set(userTypeName,newUserType);
      this.saveAllUserTypes(categoryMap);
      return newUserType;
    }
    return null;
  }

  getAllUserTypes(): Map<string,StorageUserType>{
    let categoryMap = new Map<string,StorageUserType>();
    let categories = localStorage.getItem(LocalStorageItem.CATEGORIES);
   let jsonObject = JSON.parse(categories);
    for (var value in jsonObject) 
    {  
      categoryMap.set(value,jsonObject[value]) 
    } 
    return categoryMap;
  }

  replaceUserType(userType: StorageUserType){
    var userTypes = this.getAllUserTypes();
    userTypes.set(userType.name,userType);
    this.saveAllUserTypes(userTypes);
  }

  saveAllUserTypes( categoryMap: Map<string,StorageUserType>){

    let jsonObject = {};  
    categoryMap.forEach((value, key) => {  
        jsonObject[key] = value  
    });  
    var categoriesStr = JSON.stringify(jsonObject);
    localStorage.setItem(LocalStorageItem.CATEGORIES,categoriesStr);
  }

  getPreferedInstitution(userTypeName: string): Institution {
    var userType = this.getUserTypeByName(userTypeName,false);
    if(userType !== null)
    {
      return userType.preferredInstitution;
    }
    return null;
  }

  savePreferedInstitution(userTypeName: string,institution: Institution) {
    var userType = this.getUserTypeByName(userTypeName,true);
    if(userType && userType.usage && institution) {
      userType.usage.institutionId = institution.id;
    }
    userType.preferredInstitution = institution;
    userType.preferredInstitution.facilities = [];
    this.replaceUserType(userType);
  }

  getVariables(userTypeName: string): Map<string,string> {
    let variables = new Map<string,string>();
    let variableList = localStorage.getItem(LocalStorageItem.VARIABLE_LIST);
    let jsonObject = JSON.parse(variableList);
    for (var value in jsonObject) 
    {  
      variables.set(value,jsonObject[value])  
    }  
    return variables;
  }

  saveVariables(userTypeName: string,variables: Map<string,string>) {
    let jsonObject = {};  
    variables.forEach((value, key) => {  
        jsonObject[key] = value  
    });  
    var variablesStr = JSON.stringify(jsonObject);
    localStorage.setItem(
      LocalStorageItem.VARIABLE_LIST,variablesStr
    );
  }

  deletePreferedInstitution(userTypeName: string) {
    var userType = this.getUserTypeByName(userTypeName,false);
    if(userType !== null)
    {
      userType.preferredInstitution = null;
    }
  }

  getUsageInfo(userTypeName: string): UsageData {
    var userType = this.getUserTypeByName(userTypeName,false);
    if(userType !== null)
    {
      return userType.usage;
    }
    return new UsageData();
  }

  saveUsageInfo(userTypeName: string,data: UsageData) {
    var userType = this.getUserTypeByName(userTypeName,true);
    if(userType.usage !== undefined && userType.usage !== null)
    {
      data.categoryName = (userType.usage.categoryName != "" && userType.usage.categoryName) ? userType.usage.categoryName : window.sessionStorage.getItem("category");
      data.institutionId = userType.usage.institutionId;
      data.sessionId = userType.user_id;
      data.referralSource = userType.usage.referralSource;
      data.latitude = userType.usage.latitude;
      data.longitude = userType.usage.longitude;
    }
    if(data.sessionId === undefined || data.sessionId === null)
      data.sessionId = userType.user_id;
    userType.usage = data;
    this.replaceUserType(userType);
  }



  deleteUsageInfo(userTypeName: string) {    
    var userType = this.getUserTypeByName(userTypeName,true);
    userType.usage = null;
    this.replaceUserType(userType);
  }

  getLanguage(userTypeName: string): string {
    var userType = this.getUserTypeByName(userTypeName,false);
    if(userType !== null)
    {
      return userType.language;
    }
    return SelectUI.DEFAULT_LANG;
  }

  saveLanguage(userTypeName: string,defaultLang: string) {
    var userType = this.getUserTypeByName(userTypeName,true);
    userType.language = defaultLang;
    this.replaceUserType(userType);
  }

  deleteLanguage(userTypeName: string) {
    var userType = this.getUserTypeByName(userTypeName,true);
    userType.language = SelectUI.DEFAULT_LANG;
    this.replaceUserType(userType);
  }

  getAcceptedDisclaimer(userTypeName: string): boolean {
    var userType = this.getUserTypeByName(userTypeName,false);
    if(userType !== null)
    {
      return userType.acceptedDisclaimer;
    }
    return false;
  }

  saveAcceptedDisclaimer(userTypeName: string,acceptedDisclaimer: boolean) {
    var userType = this.getUserTypeByName(userTypeName,true);
    userType.acceptedDisclaimer = acceptedDisclaimer;
    this.replaceUserType(userType);
  }

  saveHouseHoldId(userTypeName: string,id: string) {
    var userType = this.getUserTypeByName(userTypeName,true);
    userType.household_id = id;
    if(!userType.usage) userType.usage = new UsageData();
    userType.usage.sessionId = id;
    this.replaceUserType(userType);
  }

  getHouseHoldId(userTypeName: string): string {
    var userType = this.getUserTypeByName(userTypeName,false);
    if(userType !== null)
    {
      return userType.household_id;
    }
    return null;
  }

  async saveSurveyId(userTypeName: string,id: string) {
    var userType = this.getUserTypeByName(userTypeName,true);
    userType.survey_id = await this.encryptionUtil.encrypt(id);
    this.replaceUserType(userType);
  }

  getSurveyId(userTypeName: string): string {
    var userType = this.getUserTypeByName(userTypeName,false);
    if(userType !== null)
    {
      return userType.survey_id;
    }
    return null;
  }

  setIsInstitutionURL(userTypeName: string,isInstitutionURL: boolean) {
    var userType = this.getUserTypeByName(userTypeName,true);
    userType.isInstitutionURL = isInstitutionURL;
    this.replaceUserType(userType);
  }

  getIsInstitutionURL(userTypeName: string) {
    var userType = this.getUserTypeByName(userTypeName,false);
    if(userType !== null)
    {
      return userType.isInstitutionURL;
    }
    return false;
  }

  saveGuidelinesParams(userTypeName: string,params: Params) {
    var userType = this.getUserTypeByName(userTypeName,true);
    userType.guidelinesParams = params;
    this.replaceUserType(userType);
  }

  getGuidelinesParams(userTypeName: string): Params {
    var userType = this.getUserTypeByName(userTypeName,false);
    if(userType !== null)
    {
      return userType.guidelinesParams;
    }
    return null;
  }

  saveCategoryDataTyge(userTypes: DataType[]) {
    localStorage.setItem(
      LocalStorageItem.CATEGORY_DATA_TYPES,
      JSON.stringify(userTypes)
    );
  }

  getCategoryDataTyge(): DataType[] {
    const types = JSON.parse(
      localStorage.getItem(LocalStorageItem.CATEGORY_DATA_TYPES)
    );
    if (types) {
      return types as DataType[];
    } else {
      return [];
    }
  }

  getCategoryByName(name: string): number {
    let types: DataType[] = this.getCategoryDataTyge();
    let category: DataType = types.find(x=>x.description.toLowerCase() === name.toLowerCase());
    if(category !== null)
      return category.id;
    return null;
  }

  getFullCategoryByName(name: string) {
    let types = this.getCategoryDataTyge();
    let category = types.find(x=>x.description.toLowerCase() === name.toLowerCase());
    if(category !== null)
      return category;
    return null;
  }

  /**
   * This method is used to move global local storage variables to under usertype. This is only used one time
   * and only for edu-family 
   * @returns void
   */
  convertToNewFormat(): void{
    var householdId = localStorage.getItem(LocalStorageItem.HOUSEHOLD_ID);
    if(householdId === undefined || householdId == null)
      return;
    var userType = this.getUserTypeByName('edu-family',true);
    let isInstitutionURL = localStorage.getItem(LocalStorageItem.IS_INSTITUTION_URL);
    userType.isInstitutionURL = isInstitutionURL === "true";
    let acceptedDisclaimer = localStorage.getItem(LocalStorageItem.ACCEPTED_DISCLAIMER);
    userType.acceptedDisclaimer = acceptedDisclaimer === "true";
    userType.guidelinesParams = null;
    const params = JSON.parse(localStorage.getItem(LocalStorageItem.GUIDELINES_PARAMS));
    if (params) {
      userType.guidelinesParams =  params as Params;
    } 
    userType.household_id = householdId;
    const defaultLang = localStorage.getItem(LocalStorageItem.LANGUAGE);
    if (defaultLang !== null)
      userType.language = defaultLang;
    const institution = JSON.parse(localStorage.getItem(LocalStorageItem.PREFERED_INSTITUTITON));
    if (institution) {
      userType.preferredInstitution = institution as Institution;
    } 
    const usageData = JSON.parse(localStorage.getItem(LocalStorageItem.USAGE_DATA));
    if (usageData) {
      userType.usage = usageData as UsageData;
    }
    this.replaceUserType(userType);
    localStorage.removeItem(LocalStorageItem.USAGE_DATA);
    localStorage.removeItem(LocalStorageItem.PREFERED_INSTITUTITON);
    localStorage.removeItem(LocalStorageItem.LANGUAGE);
    localStorage.removeItem(LocalStorageItem.GUIDELINES_PARAMS);
    localStorage.removeItem(LocalStorageItem.ACCEPTED_DISCLAIMER);
    localStorage.removeItem(LocalStorageItem.IS_INSTITUTION_URL);
    localStorage.removeItem(LocalStorageItem.HOUSEHOLD_ID);
    localStorage.removeItem(LocalStorageItem.USAGE_DATA);
  }

  /**
   * 
   * @param userTypeName usertype
   * @returns survey user id
   */
  getSurveyUserId(userTypeName: string): string{
    var userType = this.getUserTypeByName(userTypeName,false);
    if(userType != null)
    {
      if(userTypeName.toLowerCase() === "edu-family")
        return userType.household_id;
      else if(userType.user_id === undefined || userType.user_id === null)
        return "SESSION_ID";
      return userType.user_id;
    }
    return null;
  }

  /**
   *
   * @param userTypeName usertype
   * @param UserId userId 
   */
  saveSurveyUserId(userTypeName: string, userId: string){
    var userType = this.getUserTypeByName(userTypeName,true);
    userType.user_id = userId;
    if(userType.usage === undefined)
      userType.usage = new UsageData();
    userType.usage.sessionId = userId;
    this.replaceUserType(userType);
  }
}
