import { CategoryByInstitution } from './category-by-institution.model';
import { ChildrenModelBase } from './children-base.model';
import { SurveyQuestion } from './survey-question.model';

export class Category extends ChildrenModelBase {
    description: string;
    preferredInstitution: number;
    surveyQuestions: SurveyQuestion[];
    relatedInstitutions: CategoryByInstitution[];
}
