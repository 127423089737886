// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  //baseUrl: "http://ecdsuserapp.rito.unmc.edu:53568",
  //baseUrl: "http://ecdsapi.rito.unmc.edu",
  baseUrl: "https://ecdsapi.rito.unmc.edu",


  //baseUrl: "http://10.8.22.183:53568",
  //baseUrl: "http://localhost:53568",
  serviceWorker: false,
  vapidPublicKey:
    "BENpHClf2uRBX9Ff4Ji8c_qPxNayZ2Gza0pjhd2B30jhNQvXTFMbn9qemyZ2_Qv3lLLRiymX2IUs3OH7N1iOOZ8"
};
