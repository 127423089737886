import { BaseClass } from "./decision.model";
import { UserAppTextToLanguageMap } from "./user-app-text.model";
import { Category } from './category.model';
import { ParagraphText } from './paragraph-text.model';

export class Language extends BaseClass {
  public languageCode: string;
  public languageDesc: string;
  public userAppTextItems: UserAppTextToLanguageMap[] = [];
  public decisions: any[] = []; // it should have a type
  public categories: Category[] = [];
  public paragraphs: ParagraphText[] = [];
}
