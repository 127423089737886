import { Category, Decision } from 'projects/shared-lib/src/public-api';
import { ParagraphText } from 'projects/shared-lib/src/lib/model/paragraph-text.model';

export class TranslateLanguage {
  constructor(
    public id: number,
    public languageCode: string,
    public languageDesc: string,
    public translation: any,
    public guidelines: Decision[],
    public categories: Category[],
    public paragraphs: ParagraphText[]
  ) {}
}
