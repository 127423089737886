import { Injectable } from "@angular/core";
import { HttpHeaders, HttpErrorResponse } from "@angular/common/http";
import { throwError } from "rxjs";
import { environment } from "../../../environments/environment";

import { InquiryResponse } from "../../../../../shared-lib/src/public-api";

@Injectable({ providedIn: "root" })
export class ServiceUtil {
  readonly rootUrl = `${environment.baseUrl}`;

  constructor() {
    this.handleError = this.handleError.bind(this);
  }

  private isUnknownError(error: any): boolean {
    return error === null || error.error === null ? true : false;
  }

  getCommonHeaders() {
    return {
      headers: new HttpHeaders({
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
        "Accept-Language": "en-US",
      }),
    };
  }

  handleError(errorResp: HttpErrorResponse) {
    let errorMessage = "An unknown error occurred";
    const isUnknown = this.isUnknownError(errorResp.error);

    if (!isUnknown) {
      errorMessage = errorResp.error.error;
    }
    return throwError(errorMessage);
  }

  getErrorMessage<T>(response: InquiryResponse<T>) {
    let errors: string = "";
    if (!response.operationSuccess) {
      response.messages.forEach((message) => {
        errors += message.text;
      });
    }
    return errors;
  }
}
