import { Injectable } from "@angular/core";

function getWindowScope(): any {
  return window;
}

@Injectable()
export class WindowUtil {
  get windowScope(): any {
    return getWindowScope();
  }
}
