import { Injectable } from "@angular/core";
import { Institution } from "projects/shared-lib/src/public-api";
import { BehaviorSubject } from "rxjs";

@Injectable({ providedIn: "root" })
export class ThemeService {
  imageURL = "../assets/childrens-h-logo.png";
  public imageURL$ = new BehaviorSubject<string>(this.imageURL);

  setActiveTheme(institution: Institution) {
    this.imageURL$.next(institution.logo);
    institution.themeElements.forEach((property) => {
      document.documentElement.style.setProperty(property.name, property.value);
    });
  }

  setInstitution(institution: Institution): void {
    if (institution === undefined || institution === null) {
      return;
    }
    this.setActiveTheme(institution);
  }
}
