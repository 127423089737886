import * as CryptoJS from 'crypto-js';  
import { Injectable } from "@angular/core";
import { environment } from "../../../environments/environment";
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ServiceUtil } from './service-util';
import { catchError, map, tap } from "rxjs/operators";
import {
  InquiryResponse
} from "../../../../../shared-lib/src/public-api";

@Injectable({ providedIn: "root" })
export class EncryptionUtil {
  private encryptionUrl = `${this.serviceUtil.rootUrl}/Encryption`;
  constructor(private serviceUtil: ServiceUtil, private http: HttpClient) {
  }

  private handleResponse(
    response
  ) {
    console.log("success")
    console.log(response)
  }

  /*encrypt(value: string): string{
    var key = CryptoJS.enc.Utf8.parse(environment.key);
    var encryptedVal = CryptoJS.AES.encrypt(value.trim(), key, {  
        keySize: 128 / 8,  
        mode: CryptoJS.mode.ECB,  
        padding: CryptoJS.pad.Pkcs7  
    });
    return encryptedVal.toString();
  }*/
  

  async encrypt(value: string) {
    console.log(value);
    const url = `${this.encryptionUrl}/Encrypt`;
    return this.http
      .post<string>(
        url,
        `\"${value}\"`,
        {
          headers: new HttpHeaders({
            'Accept': 'text/plain',
            "Access-Control-Allow-Origin": "*",
            "Content-Type": "application/json",
            "Accept-Language": "en-US",
            responseType: 'text'
          }),
        }
      )
      .pipe(
        catchError(this.serviceUtil.handleError),
        tap(this.handleResponse),
        map(res => res.returnValues[0])
      ).toPromise();
  }

  async decrypt(value: string) {
    console.log(value);
    const url = `${this.encryptionUrl}/Encrypt`;
    return this.http
      .post<string>(
        url,
        `\"${value}\"`,
        {
          headers: new HttpHeaders({
            'Accept': 'text/plain',
            "Access-Control-Allow-Origin": "*",
            "Content-Type": "application/json",
            "Accept-Language": "en-US",
            responseType: 'text'
          }),
        }
      )
      .pipe(
        catchError(this.serviceUtil.handleError),
        tap(this.handleResponse),
        map(res => res.returnValues[0])
      ).toPromise();
  }

  /*decrypt(encrypted: string): string {
    
    var key = CryptoJS.enc.Utf8.parse(environment.key);
    var decryptedVal = CryptoJS.AES.decrypt(encrypted.trim(), key, {  
        keySize: 128 / 8,  
        mode: CryptoJS.mode.ECB,  
        padding: CryptoJS.pad.Pkcs7  
    });
    return decryptedVal.toString(CryptoJS.enc.Utf8);
  }*/

}
