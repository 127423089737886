import { Message } from './message.model';

export class InquiryResponse<T>  {
    public pageReturned: number;
    public totalItems: number;
    public hasMorePages: boolean;
    public returnValues: T[];
    public first: T;
    public messages: Message[];
    public operationSuccess: boolean;
    public isInError: boolean;
    public hasWarnings: boolean;

    constructor() {}
}
