import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { SanitizerPipe } from "./lib/pipe/sanitizer.pipe";

@NgModule({
  imports: [CommonModule],
  declarations: [SanitizerPipe],
  exports: [SanitizerPipe],
})
export class SharedLibraryModule {}
