import { NgModule, SkipSelf, Optional } from "@angular/core";
import { CommonModule } from "@angular/common";
import { EnsureModuleLoadedOnceGuard } from "../../guard/ensure-module-loaded-once.guard";
import { ToasterContainerComponent } from "./toaster-container.component";
import { ToasterComponent } from "./toaster.component";

@NgModule({
  imports: [CommonModule],
  declarations: [ToasterContainerComponent, ToasterComponent],
  exports: [ToasterContainerComponent, ToasterComponent],
})
export class ToasterModule extends EnsureModuleLoadedOnceGuard {
  // Ensure that CoreModule is only loaded into AppModule
  constructor(@Optional() @SkipSelf() parentModule: ToasterModule) {
    super(parentModule);
  }
}
