export class PathwayByInstitution {
  constructor(
    public pathwayId: number,
    public institutionId: number,
    public rowStatus: boolean,
    public createdBy: string,
    public createdDate: any,
    public updatedBy: string,
    public updatedDate: any,
    public modelAction: number
  ) {}
}
