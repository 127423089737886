import { v4 as uuid } from "uuid";

export class UsageData {
  public deviceId: string;
  public sessionId: string;
  public startDate: Date;
  public latitude: number;
  public longitude: number;
  public referralSource: string;
  public categoryName: string;
  public institutionId: number;
  public languageCode: string;

  constructor() {
    this.deviceId = uuid();
    this.sessionId = "";
    this.latitude = 0;
    this.longitude = 0;
    this.referralSource = "";
    this.categoryName = "";
    this.institutionId = 0;
  }
}
