import { Component, OnInit, Input, OnDestroy } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import { UrlUtil } from "../../../shared/utils/url.util";
import { TranslateLanguage } from "../../../shared/model/translate-language.model";
import { LocalStorageUtil } from "../../../shared/utils/local-storage.util";
import { DataStoreService } from "../../services/data-store.service";
import { ThemeService } from "../../services/theme.service";
import { Subscription } from "rxjs";

@Component({
  selector: "app-header",
  templateUrl: "./header.component.html",
  styleUrls: ["./header.component.css"],
})
export class HeaderComponent implements OnInit, OnDestroy {
  @Input() sidenav;
  public languages: TranslateLanguage[] = [];
  public enableLangDropDown: boolean;
  private subscriptions: Subscription[] = [];

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private urlUtil: UrlUtil,
    private localStorageUtil: LocalStorageUtil,
    public themeService: ThemeService,
    public dataStoreService: DataStoreService,
    public translateService: TranslateService
  ) {
    this.enableLangDropDown = false;
    this.setup = this.setup.bind(this);
    this.detectRouteChanged = this.detectRouteChanged.bind(this);
    this.onUpdateLanguages = this.onUpdateLanguages.bind(this);
  }

  private getUserTypeName(): string{
    return this.route.snapshot.params["category"];
  }
  private onUpdateLanguages(languages: TranslateLanguage[]) {
    this.languages = [...languages];
  }

  private setup() {
    const institution = this.localStorageUtil.getPreferedInstitution(this.getUserTypeName());
    const hasInstitution = !!institution;
    if (hasInstitution) {
      this.themeService.setInstitution(institution);
    }
  }

  detectRouteChanged() {
    const tree = this.router.parseUrl(this.router.url);
    this.enableLangDropDown = this.urlUtil.isGuidelineRoute(tree);
  }

  switchLang(languageCode: string) {
    const langCode = languageCode.toLowerCase();
    this.translateService.resetLang(langCode);
    this.translateService.use(langCode);
    this.dataStoreService.setCurrentGuidelines(langCode);
    this.localStorageUtil.saveLanguage(this.getUserTypeName(),langCode);
    this.urlUtil.updateLangParam(langCode);
  }

  toggleSideNav() {
    this.sidenav.toggle();
  }

  ngOnInit() {
    this.route.url.subscribe(this.detectRouteChanged);
    this.subscriptions.push(
      this.dataStoreService.languages$.subscribe(this.onUpdateLanguages)
    );
    this.setup();
  }

  ngOnDestroy() {
    this.subscriptions.forEach((s) => s.unsubscribe());
  }
}
