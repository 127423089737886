import { Injectable } from '@angular/core';
import { ServiceUtil } from "../../shared/utils/service-util";
import { HttpClient } from '@angular/common/http';
import { InquiryResponse, Catalog } from 'projects/shared-lib/src/public-api';
import { catchError, tap } from 'rxjs/operators';

@Injectable()
export class CatalogService {
    private catalognUrl = `${this.serviceUtil.rootUrl}/catalog`;

  constructor(private serviceUtil: ServiceUtil, private http: HttpClient) {}

  getCatalog() {
    const url = `${this.catalognUrl}/fetch`;
    return this.http
      .post<InquiryResponse<Catalog>>(
        url,
        {},
        this.serviceUtil.getCommonHeaders()
      )
      .pipe(
        catchError(this.serviceUtil.handleError),
        tap(res => res)
      );
  }
}