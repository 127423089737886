export class LocalStorageItem {
  public static ADMIN_DATA = "ADMIN_DATA";
  public static USER_DATA = "MOBILE_USER_DATA";
  public static USAGE_DATA = "MOBILE_USAGE_DATA";
  public static LANGUAGE = "LANGUAGE";
  public static HOUSEHOLD_ID = "HOUSEHOULD_ID";
  public static PREFERED_INSTITUTITON = "PREFERED_INSTITUTITON";
  public static IS_INSTITUTION_URL = "IS_INSTITUTION_URL";
  public static GUIDELINES_PARAMS = "GUIDELINES_PARAMS";
  public static ACCEPTED_DISCLAIMER = "ACCEPTED_DISCLAIMER";
  public static QS_GROUP = "QS_GROUP";
  public static SURVEY_ID = "SURVEY_ID";
  public static VARIABLE_LIST = "VARIABLE_LIST";
  public static CATEGORY_DATA_TYPES = "CATEGORY_DATA_TYPES";
  public static CATEGORIES = "CATEGORIES";
}
