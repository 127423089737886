import { PathwayByInstitution } from "./pathway-by-Institution.model";
import { LanguageText } from "./language-text.model";
import { PathwayByTree } from "./pathway-by-tree.model";
import { OptionVariable } from "./option-variable.model";

export class BaseClass {
  public id: number;
  public rowStatus: boolean;
  public displayOrder: number;
  public createdBy: string;
  public createdDate: any;
  public updatedBy?: string;
  public updatedDate?: any;
  public tag: string;
  public modelAction: number;
}

export class Pathway extends BaseClass {
  public name: string;
  public description: string;
  public generalConsideration: string;
  public additionalInformation: string;
  public startingQuestionGroupId: number;
  public questionGroupList: QuestionGroup[] = [];
  public treatmentList: Treatment[] = [];
  public decisionId: number;
  public institutionId: number;
  public pathwayStatusId: number;
  public byInstitutions: PathwayByInstitution[];
  public textList: LanguageText[];
  public byTreeList: PathwayByTree[];
}

export class Decision extends BaseClass {
  public name: string;
  public description: string;
  public disclaimer: string;
  public additionalInformation: string;
  public pathways: any[]; // it should have a type
  public logo: string;
  public categoryId: number;
  public textList: LanguageText[];
}

export class RelatedObject {
  id: number;
  text: string;
  tag: string;
  isQuestionGroup: boolean;
  isTemplate: boolean;

  SetToEmpty() {
    this.id = 0;
    this.text = "";
    this.tag = "";
    this.isTemplate = false;
  }

  constructor() {
    this.isTemplate = true;
  }
}

export class Treatment extends BaseClass {
  text: string;
  parentPathwayId?: number;
  templateTreatmentId?: number;
  templateQuestionGroupId?: number;
  show?: boolean;
  textList: LanguageText[];
  isSaveAs?: boolean = false;
}

export class Option extends BaseClass {
  text: string;
  weight: number;
  nextQuestionGroupId?: number;
  treatmentId?: number;
  relatedObject?: RelatedObject;
  isSelected?: boolean;
  textList: LanguageText[];
  variableList: OptionVariable[];
  variables?: string[];
  copyVariables?: boolean;
}

export class Question extends BaseClass {
  options: Option[];
  text: string;
  multipleSelection?: boolean;
  textList: LanguageText[];
}

export class ScoreToNextObject extends BaseClass {
  score: string;
  nextQuestionGroupId?: number;
  treatmentId?: number;
  relatedObject?: RelatedObject;
}

export class QuestionGroup extends BaseClass {
  isScoreBased: boolean;
  isStepBased?: boolean;
  questions: Question[];
  scoreToNextObjectList: ScoreToNextObject[];
  version?: number;
  parentPathwayId?: number;
  templateQuestionGroupId?: number;
  show?: boolean;
  textList: LanguageText[];
  isSaveAs?: boolean = false;
}
