import { Injectable } from "@angular/core";
import { LocalStorageUtil } from "projects/MobileApp/src/app/shared/utils/local-storage.util";
import { DataType } from "projects/shared-lib/src/public-api";

@Injectable({ providedIn: "root" })
export class CategoryDataTypeUtil {
  categories: DataType[] = [];
  constructor(private localStorage: LocalStorageUtil) {
    this.categories = this.localStorage.getCategoryDataTyge();
  }

  getCategoryId(categoryName: string): number {
    this.categories = this.localStorage.getCategoryDataTyge();
    return this.categories
      .filter(
        (category) =>
          category.description.toLowerCase() === categoryName.toLowerCase()
      )
      .map((category) => category.id)[0];
  }

  isEduFamily(categoryDesc: string): boolean {
    return categoryDesc.toLowerCase() === "edu-family";
  }

}
