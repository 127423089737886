import { EventEmitter, Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { catchError, tap } from "rxjs/operators";
import { ServiceUtil } from "../../shared/utils/service-util";

import {
  InquiryResponse,
  Language,
} from "../../../../../shared-lib/src/public-api";

@Injectable({ providedIn: "root" })
export class LanguageService {
  private languageUrl = `${this.serviceUtil.rootUrl}/language`;
  
  institutionChange: EventEmitter<any> = new EventEmitter();
  langDataChange: EventEmitter<any> = new EventEmitter();

  constructor(private serviceUtil: ServiceUtil, private http: HttpClient) {
    this.serviceUtil = serviceUtil;
    this.http = http;
    this.handleGetLanguagesResponse = this.handleGetLanguagesResponse.bind(
      this
    );
  }

  private handleGetLanguagesResponse(response: InquiryResponse<any>) {
    console.log("handleGetLanguagesResponse")
    console.log(response)
  }

  emitInstitutionChange(institutionId: number) {
    this.institutionChange.emit(institutionId);
  }

  getInstitutionChangeEmitter(): EventEmitter<any> {
    return this.institutionChange;
  }

  emitlangDataChange(data) {
    this.langDataChange.emit(data);
  }

  getlangDataChangeEmitter(): EventEmitter<any> {
    return this.langDataChange;
  }

  getAdminLanguages() {
    const url = `${this.languageUrl}/FetchWithGuidelines`;
    return this.http
      .get<InquiryResponse<Language>>(url, this.serviceUtil.getCommonHeaders())
      .pipe(
        catchError(this.serviceUtil.handleError),
        tap(this.handleGetLanguagesResponse)
      );
  }

  getUserLanguages(userType = "", institution = 0) {
    const url = `${this.languageUrl}/FetchWithGuidelinesAndPublishedPathway`;
    var params = (institution == null || institution == 0)?  `?categoryName=${userType}` : `?categoryName=${userType}&institutionId=${institution}`;
    var header = {
      headers: new HttpHeaders({
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
        "Accept-Language": "en-US",
        "Content-Encoding": "gzip"
      }),
    }
    return this.http
    .get<InquiryResponse<any>>(url+params, header)
    //.get<InquiryResponse<Language>>(url, header)
      .pipe(
        catchError(this.serviceUtil.handleError),
        tap(this.handleGetLanguagesResponse)
      );
  }
}
