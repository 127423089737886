import { Injectable } from "@angular/core";
import { UsageData } from "../../shared/model/usage-data.model";

import {
  PersistenceAction,
  Usage,
  UsageDetail,
} from "../../../../../shared-lib/src/public-api";
import { LocalStorageUtil } from './local-storage.util';

@Injectable({ providedIn: "root" })
export class UsageControlUtil {
  constructor(private localStorageUtil: LocalStorageUtil) {}

  private parseToUsage(usageStr: string): Usage {
    return JSON.parse(usageStr) as Usage;
  }

  toSaveUsage(usageData: UsageData, tags: string[]): Usage {
    const usageDetails = tags.map((tag) => {
      const usageDetail = {
        id: 0,
        usageId: 0,
        tag,
        modelAction: PersistenceAction.INSERT,
        rowStatus: true,
      } as UsageDetail;

      return usageDetail;
    }) as UsageDetail[];
    
    const usage = {
      id: 0,
      deviceId: usageData.deviceId,
      sessionId: this.localStorageUtil.getHouseHoldId(usageData.categoryName) ? this.localStorageUtil.getHouseHoldId(usageData.categoryName) 
            : this.localStorageUtil.getSurveyUserId(usageData.categoryName),
      categoryId: 0,
      startDate: new Date(usageData.startDate),
      endTime: new Date(),
      latitude: usageData.latitude,
      longitude: usageData.longitude,
      referralSource: usageData.referralSource,
      categoryName: (usageData.categoryName != "" && usageData.categoryName) ? usageData.categoryName : window.sessionStorage.getItem("category") ,
      institutionId: usageData.institutionId,
      languageCode: usageData.languageCode,
      usageDetails,
      modelAction: PersistenceAction.INSERT,
      rowStatus: true,
    } as Usage;
    return usage;
  }

  toString(usage: Usage) {
    return JSON.stringify(usage);
  }

  toUsage(usagesStr: string[]): Usage[] {
    return usagesStr.map(this.parseToUsage);
  }
}
