<mat-sidenav-container>
  <mat-sidenav #sidenav fixedInViewport="true" [mode]="'over'" class="sidebar">
    <app-sidebar></app-sidebar>
  </mat-sidenav>
  <mat-sidenav-content>
    <app-header [sidenav]="sidenav"></app-header>
    <div class="content-container">
      <div class="content">
        <router-outlet></router-outlet>
      </div>
    </div>
  </mat-sidenav-content>
</mat-sidenav-container>
