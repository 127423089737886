import { BaseClass } from './decision.model';

export class UserAppText extends BaseClass
{
    text: string;
}

export class UserAppTextToLanguageMap extends BaseClass
{
    languageId: number;
    userAppTextId: number;
    text: string;
    appText: string;
}