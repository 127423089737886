import { Component, OnInit, OnDestroy } from "@angular/core";
import { SpinnerService } from "./spinner.service";

@Component({
  selector: "app-spinner",
  templateUrl: "./spinner.component.html",
  styleUrls: ["./spinner.component.css"],
})
export class SpinnerComponent implements OnInit, OnDestroy {
  numberOfWaits: number = 0;
  constructor(private spinnerService: SpinnerService) {}

  ngOnInit() {
    this.spinnerService.register(this);
  }

  ngOnDestroy() {
    this.spinnerService.unregister(this);
  }

  showSpinner(): void {
    this.numberOfWaits++;
  }

  hideSpinner(): void {
    this.numberOfWaits--;
  }
}
