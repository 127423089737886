import { Params } from "@angular/router";
import { UsageData } from "projects/MobileApp/src/app/shared/model/usage-data.model";
import { Institution } from "projects/shared-lib/src/public-api";


export class StorageUserType {
  name: string;
  household_id: string;
  survey_id: string;
  isInstitutionURL: boolean;
  guidelinesParams: Params;
  acceptedDisclaimer: boolean;
  language: string;
  usage: UsageData;
  preferredInstitution: Institution;
  variableList: Map<string,string>;
  user_id: string;
}
