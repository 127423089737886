import { Injectable } from "@angular/core";
import { TranslateLanguage } from "../model/translate-language.model";
import { Language, Decision } from "projects/shared-lib/src/public-api";
import { QuestionOption, SurveyQuestion } from "projects/shared-lib/src/public-api";

@Injectable({ providedIn: "root" })
export class LanguageUtil {
  constructor() {}

  private isNotEqualLanguageByCode(langCode: string) {
    return (transLang: TranslateLanguage): boolean =>
      transLang.languageCode.toLowerCase() !== langCode.toLowerCase();
  }

  private isEqualLanguageByCode(langCode: string) {
    return (transLang: TranslateLanguage): boolean =>
      transLang.languageCode.toLowerCase() === langCode.toLowerCase();
  }

  private mapUserAppItems(userAppItems: any[]): {} {
    const translation = {};
    userAppItems.forEach((userAppItem) => {
      translation[userAppItem.appText] = userAppItem.text;
    });
    return translation;
  }

  public mapToTranslateLanguage(languages: Language[]): TranslateLanguage[] {
    return languages.map((langToMap) => {
      const translation = this.mapUserAppItems(langToMap.userAppTextItems);
      return new TranslateLanguage(
        langToMap.id,
        langToMap.languageCode,
        langToMap.languageDesc,
        translation,
        langToMap.decisions,
        langToMap.categories,
        langToMap.paragraphs
      );
    });
  }

  public mapLanguagesToLangCode(languages: TranslateLanguage[]) {
    return languages.map((language) => language.languageCode.toLowerCase());
  }

  public filterTranslationsByLangCode(
    translateLanguages: TranslateLanguage[],
    langCode: string
  ): TranslateLanguage[] {
    if (langCode) {
      return translateLanguages.filter(this.isNotEqualLanguageByCode(langCode));
    } else {
      return [];
    }
  }

  public isValidLang(
    translateLanguages: TranslateLanguage[],
    langCode: string
  ): boolean {
    const transLangFound: TranslateLanguage = translateLanguages.find(
      this.isEqualLanguageByCode(langCode)
    );
    return transLangFound ? true : false;
  }

  public findTranslationByLang(
    translateLanguages: TranslateLanguage[],
    langCode: string
  ): {} {
    const transLangFound: TranslateLanguage = translateLanguages.find(
      this.isEqualLanguageByCode(langCode)
    );
    return transLangFound ? transLangFound.translation : {};
  }

  public findTranslateLanguageByCode(
    translateLanguages: TranslateLanguage[],
    langCode: string
  ): TranslateLanguage {
    const transLangFound: TranslateLanguage = translateLanguages.find(
      this.isEqualLanguageByCode(langCode)
    );
    return transLangFound ? transLangFound : null;
  }

  public findGuidelinesByLang(
    translateLanguages: TranslateLanguage[],
    langCode: string
  ): Decision[] {
    const transLangFound: TranslateLanguage = translateLanguages.find(
      this.isEqualLanguageByCode(langCode)
    );
    return transLangFound ? transLangFound.guidelines : [];
  }

  public translateSurveyQuestion(translateLanguages: TranslateLanguage[], item: SurveyQuestion | QuestionOption, lang: string) {
    if(!lang) return item.description; 
    let langFound = translateLanguages.find(t => t.languageCode.toUpperCase() == lang.toUpperCase());
    if(!langFound) return item.description; 
    let trans = item.textList.find(t => t.languageId == langFound.id );
    if(!trans) return item.description;  
    return trans.value;
  }
}
