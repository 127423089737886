import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";

import { ServiceUtil } from "../../shared/utils/service-util";
import {
  InquiryResponse,
  Usage,
} from "../../../../../shared-lib/src/public-api";
import { tap } from "rxjs/operators";
import { UsageRequest } from "../../shared/model/usage-request.model";

@Injectable({ providedIn: "root" })
export class UsageService {
  private usageUrl = `${this.serviceUtil.rootUrl}/usage`;

  constructor(private serviceUtil: ServiceUtil, private http: HttpClient) {
    this.serviceUtil = serviceUtil;
    this.http = http;
    this.handleSaveUsageResponse = this.handleSaveUsageResponse.bind(this);
    this.handleSaveUsagesResponse = this.handleSaveUsagesResponse.bind(this);
  }

  private handleSaveUsageResponse(response: InquiryResponse<any>) {}

  private handleSaveUsagesResponse(response: InquiryResponse<any>) {}

  public saveUsage(data: Usage) {
    const url = `${this.usageUrl}/save`;
    return this.http
      .post<InquiryResponse<any>>(
        url,
        data,
        this.serviceUtil.getCommonHeaders()
      )
      .pipe(tap(this.handleSaveUsageResponse));
  }

  public saveUsages(data: UsageRequest) {
    const url = `${this.usageUrl}/savelist`;
    return this.http
      .post<InquiryResponse<UsageRequest>>(
        url,
        data,
        this.serviceUtil.getCommonHeaders()
      )
      .pipe(tap(this.handleSaveUsagesResponse));
  }
}
