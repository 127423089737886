import { Pipe, PipeTransform } from "@angular/core";
import { TranslateLanguage } from "../model/translate-language.model";
import { LanguageUtil } from "../utils/language.util";

@Pipe({ name: "findLangByCode" })
export class FindLangByCodePipe implements PipeTransform {
  constructor(private languageUtil: LanguageUtil) {}

  transform(translateLanguages: TranslateLanguage[], langCode: string): string {
    const translation = this.languageUtil.findTranslateLanguageByCode(
      translateLanguages,
      langCode
    );
    return translation.languageCode.toLowerCase()
      ? translation.languageDesc.toLowerCase()
      : "";
  }
}
