import { Injectable } from "@angular/core";
import { HttpErrorResponse } from "@angular/common/http";
import { forkJoin, Observable, of, Subscription } from "rxjs";

import {
  Decision,
  Pathway,
  QuestionGroup,
  Treatment,
} from "../../../../../shared-lib/src/public-api";

import { UsageService } from "./usage.service";
import { LocalStorageUtil } from "../../shared/utils/local-storage.util";
import { UsageControlUtil } from "../../shared/utils/usage.util";
import {
  ExternalUrlUtility,
  InquiryResponse,
  Institution,
  Usage,
} from "../../../../../shared-lib/src/public-api";
import { LocalDatabaseService } from "./local-database.service";
import { UsageRequest } from "../../shared/model/usage-request.model";
import { Params } from "@angular/router";
import { ReferralSource } from "../../shared/constant/referral-source.constant";
import { SurveyValiableUtil } from "../../shared/utils/survey-variable.util";
import { CategoryDataTypeUtil } from "projects/AdminApp/src/app/shared/util/category-data-type.util";
import { PersistenceAction } from "shared-lib";
import { UsageData } from "../../shared/model/usage-data.model";

@Injectable({ providedIn: "root" })
export class UserAppService {

  private languagesSub$: Subscription;
  public currentDecision: Decision;
  public currentPathway: Pathway;
  public currentTreatment: Treatment;
  public currentGroups: QuestionGroup[] = [];
  public allDecisions: Decision[] = [];
  public currentInstitution: Institution;
  public tags: string[] = [];
  source: string;
  mapGroup: Map<number, QuestionGroup> = new Map<number, QuestionGroup>();
  mapTreatment: Map<number, Treatment> = new Map<number, Treatment>();
  dataFetched: Date;
  loggedIn: boolean = false;
  private tagCount: number = 4;
  
  constructor(
    private localDatabaseService: LocalDatabaseService,
    private usageService: UsageService,
    private localStorageUtil: LocalStorageUtil,
    private usageControlUtil: UsageControlUtil,
    private dataTypeUtil: CategoryDataTypeUtil,
    private externalServiceUtil: ExternalUrlUtility,
    private surveyValiableUtil: SurveyValiableUtil
  ) {
    this.onSaveUsagesSuccess = this.onSaveUsagesSuccess.bind(this);
    this.sendLocalUsageData = this.sendLocalUsageData.bind(this);
    this.onPostUsageError = this.onPostUsageError.bind(this);
    this.onUsageSuccess = this.onUsageSuccess.bind(this);
    this.requestLocationAccess = this.requestLocationAccess.bind(this);
  }

  private getPosition(isIOS: boolean) {
    const promise = new Promise<any>((resolve, reject) => {
      if (!isIOS) {
        navigator.geolocation.getCurrentPosition(
          (position) => {
            resolve({
              latitude:
                position && position.coords ? position.coords.latitude : 0,
              longitude:
                position && position.coords ? position.coords.longitude : 0,
            });
          },
          (err) => {
            resolve({
              latitude: 0,
              longitude: 0,
            });
          }
        );
      } else {
        resolve({
          latitude: 0,
          longitude: 0,
        });
      }
    });
    return promise;
  }

  private onSaveUsagesSuccess(response: InquiryResponse<UsageRequest>) {
    if (response.operationSuccess) {
      this.localDatabaseService
        .deleteAllUsage()
        .then((resp) => {})
        .catch((error) => {});
    }
  }

  private sendLocalUsageData(usageList: Usage[]) {
    if (usageList.length > 0) {
      const usageRequest = new UsageRequest(usageList);
      let usagesObs: Observable<InquiryResponse<UsageRequest>>;
      usagesObs = this.usageService.saveUsages(usageRequest);
      usagesObs.subscribe(this.onSaveUsagesSuccess, (error) => {});
    }
  }

  public filterGuidelines(params: Params, guidelines: Decision[]) 
  : Decision[]
  {
    let filteredGuidelines: Decision[] = [];
    let categoryId = -1;
    let institutionId = -1;
    if(params?.categoryName)
    {
      categoryId = this.dataTypeUtil.getCategoryId(params.categoryName);
      var institution = this.localStorageUtil.getPreferedInstitution(params.categoryName);
      if(institution !== undefined && institution !== null)
        institutionId = institution.id;
    }
    else
    {
      categoryId = this.dataTypeUtil.getCategoryId(params.category);
      var institution = this.localStorageUtil.getPreferedInstitution(params.category);
      if(institution !== undefined && institution !== null)
        institutionId = institution.id;
    }
    if(institutionId !== -1)
    {
      filteredGuidelines = this.filterGuidelinesByParams(
        guidelines,
        categoryId,
        institutionId
      );
    }
    else if (params?.institutionId && (params?.category || params?.categoryName)) {
      filteredGuidelines = this.filterGuidelinesByParams(
        guidelines,
        categoryId,
        +params.institutionId
      );
    } else if (params?.category) {
      filteredGuidelines = this.filterGuidelinesByCategory(
        guidelines,
        categoryId
      );
    }
    else{
      filteredGuidelines = guidelines;
    }
    this.guidelinesFormat(filteredGuidelines);
    return filteredGuidelines;
  }

  private filterPathways(
    guidelines: Decision[],
    institutionId: number
  ): Decision[] {
    guidelines.forEach((g) => {
      for (let i = g.pathways.length - 1; i >= 0; i--) {
        const p = g.pathways[i] as Pathway;
        let v1 = p.institutionId !== institutionId;
        let v2 = p.byInstitutions.find(
          (byInstitution) => byInstitution.institutionId === institutionId
        );
        if (v1 && (v2 === undefined || !v2)) {
          g.pathways.splice(i, 1);
        }
      }
    });
    return guidelines;
  }

  private filterGuidelinesByCategory(
    guidelines: Decision[],
    categoryId: number
  ): Decision[] {
    return guidelines.filter(
      (guideline) => guideline.categoryId === categoryId
    );
  }

  private filterGuidelinesByParams(
    guidelines: Decision[],
    categoryId: number,
    institutionId: number
  ): Decision[] {
    const guidelinesByCat = guidelines.filter(
      (guideline) => guideline.categoryId === categoryId
    );
    return this.filterPathways(guidelinesByCat, institutionId);
  }

  private isIOSPlatform(userTypeName: string): boolean {
    var usage = this.localStorageUtil.getUsageInfo(userTypeName);
    if(usage === undefined)
      return false;
    const { referralSource } = this.localStorageUtil.getUsageInfo(userTypeName);
    return referralSource?.toLocaleLowerCase() === ReferralSource.IOS
      ? true
      : false;
  }

  public requestLocationAccess(userTypeName: string) {
    const isIOS = this.isIOSPlatform(userTypeName);
    this.getPosition(isIOS).then((coordinates) => {
      const usageData = this.localStorageUtil.getUsageInfo(userTypeName);
      if(usageData == undefined)
        return;
      usageData.latitude = coordinates.latitude;
      usageData.longitude = coordinates.longitude;
      this.localStorageUtil.saveUsageInfo(userTypeName,usageData);
    });
  }

  private guidelinesFormat(guidelines: Decision[]) {
    this.allDecisions = guidelines;
    for (let i = this.allDecisions.length - 1; i >= 0; i--) {
      if (this.allDecisions[i].pathways.length === 0) {
        this.allDecisions.splice(i, 1);
      }
    }
    this.allDecisions.forEach((d) => {
      d.additionalInformation = this.externalServiceUtil.processForExternalUrls(
        d.additionalInformation,
        location.hostname
      );
      d.disclaimer = this.externalServiceUtil.processForExternalUrls(
        d.disclaimer,
        location.host
      );
      d.pathways.forEach((p) => {
        p.generalConsideration = this.externalServiceUtil.processForExternalUrls(
          p.generalConsideration,
          location.host
        );
        p.questionGroupList.forEach((qg) => {
          qg.questions.forEach((q) => {
            q.options.forEach((o) => {
              if (o.isSelected === undefined) {
                o["isSelected"] = false;
              }
            });
          });
        });
      });
    });
  }

  public set decisions(decisions: Decision[]) {
    this.selectDecision = null;
    this.allDecisions = decisions;
  }

  public set selectDecision(decision: Decision) {
    if (decision == null) {
      this.selectPathway = null;
      this.tags.length = 0;
    } else {
      this.tags.push(decision.tag);
    }
    this.currentDecision = decision;
  }

  public restartPathway(userTypeName: string) {
    this.tags.length = 2;//Keep the decision and pathway in memory
    this.saveStartTime(userTypeName);
    this.saveUsageInformationOnModelAction(userTypeName, PersistenceAction.INSERT, true);
  }

  private createGroupMap(groups: QuestionGroup[]) {
    for (let g of groups) {
      this.mapGroup.set(g.id, g);
    }
  }

  private createTreatmentMap(treatments: Treatment[]) {
    for (let t of treatments) {
      this.mapTreatment.set(t.id, t);
    }
  }

  public set selectPathway(pathway: Pathway) {
    this.mapGroup.clear();
    this.mapTreatment.clear();
    this.currentPathway = pathway;
    this.currentGroups.length = 0;
    this.currentTreatment = null;
    if (this.tags.length >= 2) {
      this.tags.length = 1;
    }
    if (pathway != null) {
      this.createGroupMap(pathway.questionGroupList);
      this.createTreatmentMap(pathway.treatmentList);
      this.tags.push(pathway.tag);
    }
  }

  private onPostUsageError(usage: Usage) {
    return (errorResp: HttpErrorResponse) => {
      if (errorResp.status === 504) {
        this.localDatabaseService.deleteAllIncompleteUsage();
        this.localDatabaseService
          .addUsage(usage)
          .then((resp) => {})
          .catch((error) => {});
      }
    };
  }

  getQuestionGroup(id: number): QuestionGroup {
    return this.mapGroup.get(id);
  }

  getTreatment(id: number): Treatment {
    return this.mapTreatment.get(id);
  }

  private onUsageSuccess(response: InquiryResponse<Usage>) {
    if (response.operationSuccess) {
      this.sendPreviousUsageData();
    }
  }

  sendPreviousUsageData(){
    this.localDatabaseService.deleteAllIncompleteUsage();
    this.localDatabaseService
      .getAllUsage()
      .then(this.sendLocalUsageData)
      .catch((error) => {});
  }

  checkPendingUsageData() {
    this.localDatabaseService
      .getAllIncompleteUsage()
      .then((usage) => {
        if (usage && usage.length) {
          this.saveUsageData(usage[0]);
        }
      })
      .catch((error) => {});
  }

  saveUsageData(data: Usage) {
    let usageObs: Observable<InquiryResponse<Usage>>;
    usageObs = this.usageService.saveUsage(data);
    usageObs.subscribe(this.onUsageSuccess, this.onPostUsageError(data));
  }

  saveInformation(userTypeName: string, sendToServiceLayer: boolean) {
    this.saveUsageInformationOnModelAction(userTypeName, PersistenceAction.UPDATE, sendToServiceLayer);
  }

  /**
   * Call save usage method on the backend based 
   * @param userTypeName 
   * @param modelAction 
   * @param sendToServiceLayer
   * @returns 
   */
  saveUsageInformationOnModelAction(userTypeName: string, modelAction: number, 
                  sendToServiceLayer: boolean) {
    const defaultLang = this.localStorageUtil.getLanguage(userTypeName);
    const usageData = this.localStorageUtil.getUsageInfo(userTypeName);
    if(!usageData) return;
    if(sendToServiceLayer === false)
    {
      if(this.tags.length % this.tagCount !== 0)
        return;
    }
    usageData.languageCode = defaultLang;
    const dataToSave = this.usageControlUtil.toSaveUsage(usageData, this.tags);
    dataToSave.modelAction = modelAction;
    this.saveUsageData(dataToSave);
  }

  getDate(item: QuestionGroup | Treatment) {
    let updatedDate = item.updatedDate;
    if (
      updatedDate == null ||
      updatedDate.toString() == "0001-01-01T00:00:00"
    ) {
      return item.createdDate.toString();
    }
    return item.updatedDate.toString();
  }

  lastUpdate(item: QuestionGroup | Treatment) {
    if (item != null) {
      let date: string = this.getDate(item);
      let idx = date.indexOf(".");
      if (idx > 1) {
        date = date.substring(0, idx);
      }
      return "last updated: " + date;
    }
    return "";
  }

  /**
   * Call this method to substitute string in variables in treatments and questions. 
   * @param guidelines Guidelines array
   */
    applySurveyVariables(userTypeName: string, guidelines: Decision[])
    {
      this.surveyValiableUtil.applySurveyVariables(userTypeName, guidelines);
    }

    public saveStartTime(userTypeName: string) {
      var usageData = this.localStorageUtil.getUsageInfo(userTypeName);
      if(!usageData) usageData = new UsageData();
      usageData.startDate = new Date();
      this.localStorageUtil.saveUsageInfo(userTypeName,usageData);
    }
}
