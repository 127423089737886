import { Pipe, PipeTransform } from "@angular/core";
import { Facility } from 'projects/shared-lib/src/lib/model/facility.model';
import { Institution } from 'projects/shared-lib/src/public-api';

@Pipe({ name: "filterFacilitiesByInst" })
export class FilterFacilitiesByInstPipe implements PipeTransform {
  constructor() {}

  private findInstitution(institutions: Institution[], institutionId: number): Institution {
    return institutions.find((institution) => institution.id === institutionId);
  }

  private filterFacilitiesByInstitutionId(institutions: Institution[], institutionId: number): Facility[] {
    const institutionFound = this.findInstitution(institutions, institutionId);
    if (institutionFound && institutionFound?.facilities.length > 0) {
      return [...institutionFound.facilities];
    } else {
      return [];
    }
  }

  transform(
    institutions: Institution[],
    institutionId: number,
  ): Facility[] {
    if (institutionId) {
      return this.filterFacilitiesByInstitutionId(institutions, institutionId);
    } else {
      return [];
    }
  }
}
